import React from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';
import AgentAssignButton from './AgentAssignButton';
import AgentUnassignButton from './AgentUnassignButton';
import { AuthRole } from '../../Utils/enums';

const MoralPersonBulkActionButtons = (props) => (
  <>
    <AgentAssignButton {...props} />
    <AgentUnassignButton {...props} />
  </>
);

const MoralPersonFilter = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput source='businessName' resettable alwaysOn />
    <TextInput source='documentNumber' resettable />
    <TextInput source='tradeName' resettable />
    <ReferenceInput
      reference='employees'
      source='agent'
      label='resources.legal-people.fields.agent'
      resettable
    >
      <AutocompleteInput
        optionText={(record) =>
          record.id &&
          `${record?.code || ''} | ${record.person?.firstName ?? ''} ${
            record.person?.fatherLastName ?? ''
          }`
        }
      />
    </ReferenceInput>
  </Filter>
);

export const MoralPersonList = ({ permissions, ...props }) => {
  const role = JSON.parse(localStorage.getItem('user')).role.id;

  return (
    <CustomList
      filters={<MoralPersonFilter />}
      bulkActionButtons={
        (role === AuthRole.CommercialLink || role === AuthRole.Admin) && (
          <MoralPersonBulkActionButtons />
        )
      }
      actions={
        <ListActions
          entity='legal-people'
          permissions={permissions}
          {...props}
        />
      }
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' />
        <TextField source='documentNumber' />
        <TextField source='businessName' />
        <FunctionField
          source='agent'
          emptyText='-'
          render={(record) =>
            record.agent
              ? `${record?.agent?.code ?? ''} | ${
                  record?.agent?.person?.firstName ?? ''
                } ${record?.agent?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <TextField source='tradeName' />
      </Datagrid>
    </CustomList>
  );
};

export default MoralPersonList;
