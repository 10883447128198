import * as React from 'react';
import { useState } from 'react';
import {
  ChipField,
  useDelete,
  useUpdate,
  useNotify,
  useRefresh,
  RichTextField,
  FormWithRedirect,
  SaveButton,
} from 'react-admin';
import {
  IconButton,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';

import { AuthRole } from '../../Utils/enums';
import NoteDeleteButton from './NoteDeleteButton';
import DraftTextInputNotes from './DraftTextInputNotes';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
  },
  metadata: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '10px',
  },
  textarea: {
    paddingTop: 16,
    paddingLeft: 14,
    paddingRight: 14,
    paddingBottom: 14,
    lineHeight: 1.3,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  cancel: {
    marginRight: theme.spacing(1),
  },
  content: {
    borderRadius: 10,
    display: 'flex',
    alignItems: 'stretch',
    minHeight: '40px',
    height: '100%',
    padding: '0px 18px',
  },
  paragraph: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: 1,
    width: '100%',
    wordWrap: 'break-word',
    '& > *': {
      margin: '0 8px',
    },
  },
  toolbar: {
    marginLeft: theme.spacing(2),
    visibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  bold: {
    fontWeight: 'bold',
  },
  width100: {
    width: '100%',
  },
  flexIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  color: {
    color: theme.palette.primary.dark,
  },
}));

const Note = ({ note, userConnect }) => {
  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);
  const [noteText, setNoteText] = useState();
  const [update, { loading }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [mentionTouched, setMentionTouched] = useState(false);
  const [mentionData, setMentionData] = useState({} as any);

  const [handleDelete] = useDelete('contact-histories', note.id, note, {
    mutationMode: 'undoable',
    onSuccess: () => {
      notify('Note deleted', undefined, undefined, true);
      refresh();
    },
  });

  const handleEnterEditMode = () => {
    setEditing(true);
    setNoteText(note.description);
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setNoteText(note.description);
  };

  const handleNoteUpdate = (values) => {
    const { htmlText, mentions, errors: mentionErrors } = mentionData;
    if (!mentionTouched) {
      setMentionTouched(true);
      return;
    }
    if (mentionErrors?.required) return;

    update(
      'contact-histories',
      note.id,
      { description: htmlText, mentions },
      note,
      {
        onSuccess: () => {
          setEditing(false);
          setNoteText(htmlText);
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  const handleChangeMention = (htmlText, mentions, errors) => {
    setMentionData({
      htmlText,
      mentions,
      errors,
    });
  };

  return (
    <Card className={classes.root} raised>
      <div className={classes.metadata}>
        <CardHeader
          title={
            <>
              {note.user.legalPerson
                ? note.user.legalPerson?.businessName
                : `${note.user.person?.firstName} ${note.user.person?.fatherLastName}`}
              {note.employee && (
                <ChipField
                  record={note}
                  source='employee.position.name'
                  style={{ marginLeft: 6 }}
                />
              )}
            </>
          }
          subheader={
            <span>
              {`${moment(note.created_at).format('DD/MM/YYYY')} ${moment(
                note.created_at
              ).format('LT')}`}
            </span>
          }
          avatar={<Avatar icon={<PersonIcon />} />}
        />
        <div>
          {note.loanApplicationState && (
            <ChipField
              record={note}
              source='loanApplicationState'
              style={{ marginRight: 6 }}
            />
          )}
          {note.contactHistoryType ? (
            <ChipField record={note} source='contactHistoryType.name' />
          ) : (
            <ChipField record={note} source='user.role.name' />
          )}
        </div>
      </div>
      {isEditing ? (
        <CardContent>
          <FormWithRedirect
            save={handleNoteUpdate}
            render={({ handleSubmitWithRedirect, saving }) => (
              <>
                <DraftTextInputNotes
                  id='textInputNotesEdit'
                  key='textInputNotesEdit'
                  onChange={handleChangeMention}
                  touched={mentionTouched}
                  setTouched={setMentionTouched}
                  defaultValue={noteText}
                  isRequired
                />
                <Button
                  className={classes.cancel}
                  onClick={handleCancelEdit}
                  color='primary'
                >
                  Cancelar
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </>
            )}
          />
        </CardContent>
      ) : (
        <>
          <CardContent className={classes.content}>
            <RichTextField
              className={classes.paragraph}
              record={note}
              source='description'
            />
          </CardContent>
          <CardActions
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <div className={classes.flexIcon}>
              <ChipField record={note} source='noteSource' emptyText='-' />
              {userConnect.id === note.user.id &&
                userConnect.role.id === AuthRole.Admin && (
                  <>
                    <IconButton onClick={handleEnterEditMode}>
                      <BorderColorIcon className={classes.color} />
                    </IconButton>
                    <IconButton>
                      <NoteDeleteButton onClick={handleDelete} note={note} />
                    </IconButton>
                  </>
                )}
            </div>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default Note;
