import { Fragment, useCallback } from 'react';
import { Datagrid, TextField, Filter, TextInput } from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, makeStyles } from '@material-ui/core';

import MaritalStatusCreate from './MaritalStatusCreate';
import MaritalStatusEdit from './MaritalStatusEdit';

import ListActions from '../../Components/ListActions';
import TablePlaceholder from '../../Components/TablePlaceholder';
import CustomList from '../../Components/CustomList';

const useStyles = makeStyles(() => ({
  drawerContent: { width: '50vw', padding: '0 1rem' },
}));

const Filters = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput label='ra.action.search' source='name' alwaysOn />
  </Filter>
);

const MaritalStatusList = ({ permissions, hasCreate, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/marital-statuses');
  }, [history]);

  return (
    <Fragment>
      <CustomList
        {...props}
        hasCreate={true}
        filters={<Filters />}
        actions={
          <ListActions
            hasCreate={true}
            isMaintenance
            entity='marital-status'
            permissions={permissions}
            {...props}
          />
        }
      >
        <Datagrid
          rowClick='edit'
          empty={<TablePlaceholder title='MaritalStatus' />}
        >
          <TextField source='id' />
          <TextField source='name' />
        </Datagrid>
      </CustomList>
      <Route path='/marital-statuses/create'>
        {({ match }) => (
          <Drawer open={!!match} anchor='right' onClose={handleClose}>
            <MaritalStatusCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
            />
          </Drawer>
        )}
      </Route>
      <Route path='/marital-statuses/:id'>
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Drawer open={isMatch} anchor='right' onClose={handleClose}>
              {isMatch ? (
                <MaritalStatusEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default MaritalStatusList;
