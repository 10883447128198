import React, { useState } from 'react';
import {
  DateInput,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  maxLength,
  useTranslate,
  BooleanInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Contacts from '../../Components/Contacts';
import Addresses from '../../Components/Addresses';
import Representatives from '../../Components/Representatives';
import FormTitle from '../../Components/FormTitle';
import InformationBank from '../../Components/InformationBank';
import FormatNumberInput from '../../Components/FormatNumberInput';
import { AuthRole, Company, DocumentType, yesNo } from '../../Utils/enums';
import OtherDocuments from '../../Components/OtherDocuments';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
    },
  })
);

export const MoralPersonForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [showDictum, setShowDictum] = useState(props?.record?.blacklist);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const role = user.role?.id;

  const record = (props as any).record || {};
  const isNew = !record?.id;
  const company =
    (isNew ? user.company?.id : record?.company) || Company.IbanOnlineMx;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <ReferenceInput
                      source='documentType.id'
                      resource='people'
                      reference='document-types'
                      fullWidth
                      disabled
                      variant='outlined'
                      validate={[required()]}
                      defaultValue={DocumentType.RFC}
                    >
                      <SelectInput optionText='name' />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      source='documentNumber'
                      resource='legal-people'
                      validate={[required(), maxLength(40)]}
                      fullWidth
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      source='businessName'
                      resource='legal-people'
                      validate={[required(), maxLength(100)]}
                      fullWidth
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      source='tradeName'
                      resource='legal-people'
                      fullWidth
                      validate={[maxLength(100)]}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      source='commercialRegister'
                      resource='legal-people'
                      fullWidth
                      validate={[maxLength(40)]}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateInput
                      source='constitutionDate'
                      resource='legal-people'
                      fullWidth
                      variant='outlined'
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateInput
                      source='registerIssueDate'
                      resource='legal-people'
                      fullWidth
                      variant='outlined'
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateInput
                      source='registerExpirationDate'
                      resource='legal-people'
                      fullWidth
                      variant='outlined'
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInput
                      label={translate(
                        'resources.legal-people.fields.constitutionCountry'
                      )}
                      source='constitutionCountry.id'
                      validate={[required()]}
                      reference='countries'
                      fullWidth
                      variant='outlined'
                    >
                      <SelectInput optionText='name' />
                    </ReferenceInput>
                  </Grid>
                  {(role === AuthRole.Risk || role === AuthRole.Admin) && (
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='billingIncome'
                        resource='legal-people'
                      />
                    </Grid>
                  )}
                  {(role === AuthRole.Risk || role === AuthRole.Admin) && (
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='statementIncome'
                        resource='legal-people'
                      />
                    </Grid>
                  )}
                  {(role === AuthRole.Risk || role === AuthRole.Admin) && (
                    <Grid item xs={4}>
                      <FormatNumberInput
                        source='financialInformationIncome'
                        resource='legal-people'
                      />
                    </Grid>
                  )}
                  {company === Company.KapitalizerOnlineMX && (
                    <Grid item xs={4}>
                      <SelectInput
                        source='hasGuarantee'
                        resource='people'
                        choices={yesNo}
                        fullWidth
                        variant='outlined'
                        defaultValue={true}
                      />
                    </Grid>
                  )}
                  {company === Company.KapitalizerOnlineMX && (
                    <Grid item xs={4}>
                      <SelectInput
                        source='isGuaranteeOwner'
                        resource='people'
                        choices={yesNo}
                        fullWidth
                        variant='outlined'
                        defaultValue={true}
                      />
                    </Grid>
                  )}
                  {[AuthRole.Risk, AuthRole.Admin].includes(role) &&
                    formProps.record?.id && (
                      <Grid item xs={4}>
                        <BooleanInput
                          source='blacklist'
                          resource='legal-people'
                          defaultValue={false}
                          onChange={(val) => setShowDictum(val)}
                        />
                      </Grid>
                    )}
                  {showDictum &&
                  [AuthRole.Risk, AuthRole.Admin].includes(role) ? (
                    <Grid item xs={4}>
                      <ReferenceInput
                        fullWidth
                        source='blacklistDictum'
                        variant='outlined'
                        reference='blacklist-dictums'
                        resource='blacklists'
                        validate={required()}
                      >
                        <SelectInput optionText='code' />
                      </ReferenceInput>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormTitle title='Otros Documentos' />
                <OtherDocuments record={formProps.record} {...props} />
              </Grid>
              <Grid item xs={12}>
                <FormTitle
                  title={translate('resources.contacts.fields.name')}
                  className={classes.width100}
                />
                <Contacts record={formProps.record} />
              </Grid>
              <Grid item xs={12}>
                <FormTitle
                  title={translate('resources.addresses.fields.name')}
                  className={classes.width100}
                />
                <Addresses record={formProps.record} />
              </Grid>
              <Grid item xs={12}>
                <FormTitle
                  title='Representantes'
                  className={classes.width100}
                />
                <Representatives {...formProps} />
              </Grid>
              <Grid item xs={12}>
                <FormTitle title={translate('Información Bancaria')} />
                <InformationBank record={formProps.record} />
              </Grid>
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

export default MoralPersonForm;
