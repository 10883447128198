import React from 'react';
import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';

import {
  academicLevels,
  assetsType,
  contactType,
  documentTypes,
  economicActivities,
  incomeTypes,
  maritalStatuses,
  occupations,
  professions,
  relatedTypes,
  referenceTypes,
} from '../../../Views';
import ListItemLink from '../ListItemLink';

const Person = ({ classes, iconColor }) => {
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant='h4' component='h2'>
          Personas
        </Typography>
        <Divider />
        <List className={classes.list} aria-labelledby='conjuration subtitle'>
          <ListItemLink
            icon={<academicLevels.icon />}
            primary='Niveles Académicos'
            to='/academic-levels'
            color={iconColor}
          />
          <ListItemLink
            icon={<professions.icon />}
            primary='Profesiones'
            to='/professions'
            color={iconColor}
          />
          <ListItemLink
            icon={<occupations.icon />}
            primary='Ocupaciones'
            to='/occupations'
            color={iconColor}
          />
          <ListItemLink
            icon={<contactType.icon />}
            primary='Tipos de Contactos'
            to='/contact-types'
            color={iconColor}
          />
          <ListItemLink
            icon={<maritalStatuses.icon />}
            primary='Estados Civiles'
            to='/marital-statuses'
            color={iconColor}
          />
          <ListItemLink
            icon={<relatedTypes.icon />}
            primary='Tipos de Relacionados'
            to='/related-types'
            color={iconColor}
          />
          <ListItemLink
            icon={<documentTypes.icon />}
            primary='Tipos de Documentos'
            to='/document-types'
            color={iconColor}
          />
          <ListItemLink
            icon={<economicActivities.icon />}
            primary='Actividades Económicas'
            to='/economic-activities'
            color={iconColor}
          />
          <ListItemLink
            icon={<incomeTypes.icon />}
            primary='Tipos de Ingresos'
            to='/income-types'
            color={iconColor}
          />
          <ListItemLink
            icon={<assetsType.icon />}
            primary='Tipos de Bienes'
            to='/asset-types'
            color={iconColor}
          />
          <ListItemLink
            icon={<referenceTypes.icon />}
            primary='Tipos de Referencias'
            to='/reference-types'
            color={iconColor}
          />
        </List>
      </Paper>
    </Grid>
  );
};

export default Person;
