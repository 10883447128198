import { Edit, ListButton, TopToolbar } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import MaritalStatusForm from './MaritalStatusForm';

const MaritalStatusActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const MaritalStatusEdit = (props: any) => {
  return (
    <Edit actions={<MaritalStatusActions {...props} />} {...props}>
      <MaritalStatusForm />
    </Edit>
  );
};

export default MaritalStatusEdit;
