import React from 'react';
import {
  AutocompleteInput,
  ChipField,
  Datagrid,
  Filter,
  NumberField,
  ReferenceInput,
  SelectInput,
  TextField,
  FunctionField,
  TextInput,
  DateInput,
  DateField,
} from 'react-admin';
import { differenceInDays, differenceInHours } from 'date-fns';

import ListActions from '../../Components/ListActions';
import FullNameField from '../../Components/FullNameField';
import CustomList from '../../Components/CustomList';

import {
  AuthRole,
  Company,
  Position,
  State,
  StateType,
} from '../../Utils/enums';
import FranchiseAssignButton from './FranchiseAssignButton';
import AnalystAssignButton from './AnalystAssignButton';
import LawyerAssignButton from './LawyerAssignButton';
import LawyerJrAssignButton from './LawyerJrAssignButton';
import ManagerAssignButton from './ManagerAssignButton';

const LoanApplicationFilter = (props) => {
  const company = JSON.parse(localStorage.getItem('user') || '{}').company?.id;
  return (
    <Filter {...props} variant='outlined'>
      <TextInput
        source='id'
        style={{ maxWidth: '100px' }}
        alwaysOn
        resettable
      />
      <DateInput
        source='created_at_gt'
        label='Ingreso de solicitud desde'
        style={{ minWidth: '190px' }}
      />
      <DateInput
        source='created_at_lt'
        label='Ingreso de solicitud hasta'
        style={{ minWidth: '190px' }}
      />
      <ReferenceInput
        reference='people'
        source='person'
        label='Persona física'
        resettable
      >
        <AutocompleteInput
          optionText={(record) =>
            record.id &&
            `${record.documentNumber || ''} | ${record.firstName || ''} ${
              record.fatherLastName ?? ''
            }`
          }
        />
      </ReferenceInput>
      <ReferenceInput reference='legal-people' source='legalPerson' resettable>
        <AutocompleteInput
          optionText={(record) =>
            record.id && `${record.documentNumber} - ${record.businessName}`
          }
        />
      </ReferenceInput>
      <ReferenceInput
        reference='states'
        source='state'
        label='Estado / Etapa'
        filter={{
          stateTypeId:
            company === Company.IbanOnlineMx
              ? StateType.LoanApplicationIO
              : StateType.LoanApplicationKap,
        }}
        resettable
      >
        <SelectInput source='name' />
      </ReferenceInput>
      <ReferenceInput
        reference='departments/zone'
        source='department'
        resettable
      >
        <AutocompleteInput />
      </ReferenceInput>
      <ReferenceInput
        reference='substates'
        source='substate'
        label='Subestado'
        resettable
      >
        <SelectInput source='name' />
      </ReferenceInput>
      {props.permissions?.employee.find.enabled && (
        <ReferenceInput reference='employees' source='agent' resettable>
          <AutocompleteInput
            optionText={(record) =>
              record.id &&
              `${record?.code || '-'} | ${record.person?.firstName ?? ''} ${
                record.person?.fatherLastName ?? ''
              }`
            }
          />
        </ReferenceInput>
      )}
      <ReferenceInput reference='franchises' source='referrer' resettable>
        <AutocompleteInput
          optionText={(record) =>
            record.id && `${record.code || '-'} | ${record.name || ''}`
          }
        />
      </ReferenceInput>
      <ReferenceInput
        reference='client-sources'
        source='clientSource'
        resettable
      >
        <AutocompleteInput />
      </ReferenceInput>
      <ReferenceInput
        reference='application-types'
        source='applicationType'
        label='resources.loan-applications.fields.applicationType.name'
        resettable
      >
        <SelectInput source='name' />
      </ReferenceInput>
    </Filter>
  );
};

const LoanApplicationBulkActionButtons = (props) => {
  const employee = JSON.parse(localStorage.getItem('employee') || '{}');

  return (
    <>
      {props?.permissions?.['loan-application']?.assignanalyst?.enabled && (
        <AnalystAssignButton {...props} />
      )}
      {([Position.SrLawyer, Position.ManagerLayer, Position.Director].includes(
        employee?.position
      ) ||
        [AuthRole.Admin].includes(props.role)) && (
        <>
          <LawyerAssignButton {...props} />
          <LawyerJrAssignButton {...props} />
        </>
      )}
      {([Position.ManagerLayer, Position.Director].includes(
        employee?.position
      ) ||
        [AuthRole.Admin].includes(props.role)) && (
        <ManagerAssignButton {...props} />
      )}
      {props?.permissions?.['loan-application']?.assignreferrer?.enabled && (
        <FranchiseAssignButton {...props} />
      )}
    </>
  );
};

const LoanApplicationList = ({ permissions, ...props }) => {
  const role = JSON.parse(localStorage.getItem('user') || '').role.id;

  const RiskList = () => (
    <CustomList
      bulkActionButtons={
        <LoanApplicationBulkActionButtons permissions={permissions} />
      }
      filters={<LoanApplicationFilter permissions={permissions} />}
      actions={
        <ListActions
          entity='loan-application'
          permissions={permissions}
          {...props}
        />
      }
      {...props}
    >
      <Datagrid
        rowClick='show'
        isRowSelectable={(record) => record?.state?.id === State.CreditAnalysis}
      >
        <TextField source='id' />
        <FunctionField
          label='Nombre del cliente'
          source='person'
          render={(record) =>
            record?.person ? (
              <FullNameField record={record} source='person' entity='person' />
            ) : (
              <TextField source='legalPerson.businessName' emptyText='-' />
            )
          }
        />
        <FunctionField
          source='analyst'
          emptyText='-'
          render={(record) =>
            record.analyst
              ? `${record?.analyst?.code ?? ''} | ${
                  record?.analyst?.person?.firstName ?? ''
                } ${record?.analyst?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <FunctionField
          source='lawyer'
          emptyText='-'
          render={(record) =>
            record.lawyer
              ? `${record?.lawyer?.code ?? ''} | ${
                  record?.lawyer?.person?.firstName ?? ''
                } ${record?.lawyer?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <NumberField
          source='amount'
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <ChipField
          label='Producto'
          source='applicationType.name'
          size='small'
        />
        <FunctionField
          label='Tasa nominal'
          source='interestRate'
          emptyText='-'
          render={(record) =>
            record.interestRate ? `${record.interestRate}%` : '-'
          }
        />
        <DateField
          showTime
          source='stateChangeDate'
          label='Fecha/Hora recepción'
          emptyText='-'
        />
        <ChipField
          label='Estatus Riesgos'
          source='substate.name'
          emptyText='-'
          size='small'
        />
        <FunctionField
          label='Rating'
          emptyText='-'
          render={(record) =>
            record?.person ? (
              <TextField source='person.savedRating.rating' emptyText='-' />
            ) : (
              <TextField
                source='legalPerson.savedRating.rating'
                emptyText='-'
              />
            )
          }
        />
        <FunctionField
          label='Ejecutivo'
          render={(record) =>
            record?.agent
              ? `${record?.agent?.code ?? ''} | ${
                  record?.agent?.person?.firstName ?? ''
                } ${record?.agent?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <ChipField source='state.name' size='small' />
        <TextField
          label='Tipo de inmueble'
          source='asset.assetType'
          sortable={false}
          emptyText='-'
        />
        <NumberField label='Plazo' source='months' />
        <NumberField
          emptyText='-'
          label='Valor de Avalúo'
          source='appraisedValue'
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <FunctionField
          label='Score'
          emptyText='-'
          render={(record) =>
            record?.person ? (
              <NumberField source='person.score' emptyText='-' />
            ) : (
              <NumberField source='legalPerson.score' emptyText='-' />
            )
          }
        />
        <FunctionField
          label='Aforo'
          emptyText='-'
          render={(record) =>
            record?.appraisedValue
              ? ((record.amount / record.appraisedValue) * 100).toFixed(2)
              : '-'
          }
        />
      </Datagrid>
    </CustomList>
  );

  const FormalizationList = () => (
    <CustomList
      bulkActionButtons={
        <LoanApplicationBulkActionButtons permissions={permissions} />
      }
      filters={<LoanApplicationFilter permissions={permissions} />}
      actions={
        <ListActions
          entity='loan-application'
          permissions={permissions}
          {...props}
        />
      }
      {...props}
    >
      <Datagrid
        rowClick='show'
        isRowSelectable={(record) => record?.state?.id === State.Formalization}
      >
        <TextField source='id' />
        <FunctionField
          label='Nombre del cliente'
          source='person'
          render={(record) =>
            record?.person ? (
              <FullNameField record={record} source='person' entity='person' />
            ) : (
              <TextField source='legalPerson.businessName' emptyText='-' />
            )
          }
        />
        <NumberField
          source='amount'
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField label='Plazo' source='months' />
        <FunctionField
          label='Tasa nominal'
          source='interestRate'
          emptyText='-'
          render={(record) =>
            record.interestRate ? `${record.interestRate}%` : '-'
          }
        />
        <ChipField
          label='Producto'
          source='applicationType.name'
          size='small'
        />
        <TextField
          label='Tipo de inmueble'
          source='asset.assetType'
          sortable={false}
          emptyText='-'
        />
        <TextField
          label='Estado inmueble'
          source='asset.province'
          sortable={false}
          emptyText='-'
        />
        <TextField source='fundingCompany.name' />
        <FunctionField
          source='agent'
          render={(record) =>
            record?.agent
              ? `${record?.agent?.code ?? ''} | ${
                  record?.agent?.person?.firstName ?? ''
                } ${record?.agent?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <FunctionField
          source='analyst'
          render={(record) =>
            record.analyst
              ? `${record?.analyst?.code ?? ''} | ${
                  record?.analyst?.person?.firstName ?? ''
                } ${record?.analyst?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <FunctionField
          source='manager'
          emptyText='-'
          render={(record) =>
            record.manager
              ? `${record?.manager?.code ?? ''} | ${
                  record?.manager?.person?.firstName ?? ''
                } ${record?.manager?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <FunctionField
          source='lawyer'
          emptyText='-'
          render={(record) =>
            record.lawyer
              ? `${record?.lawyer?.code ?? ''} | ${
                  record?.lawyer?.person?.firstName ?? ''
                } ${record?.lawyer?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <FunctionField
          source='lawyerJr'
          emptyText='-'
          render={(record) =>
            record.lawyerJr
              ? `${record?.lawyerJr?.code ?? ''} | ${
                  record?.lawyerJr?.person?.firstName ?? ''
                } ${record?.lawyerJr?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <ChipField source='state.name' size='small' />
        <DateField
          source='stateChangeDate'
          label='Fecha/Hora recepción'
          emptyText='-'
          showTime
        />
        <FunctionField
          source='stateChangeDate'
          label='Días en bandeja'
          emptyText='-'
          render={(record) =>
            record.stateChangeDate
              ? differenceInDays(new Date(), new Date(record.stateChangeDate))
              : '-'
          }
        />
        <TextField
          label='Subestado'
          source='substate.name'
          sortable={false}
          emptyText='-'
        />
        <DateField
          source='subStateChangeDate'
          label='Fecha/Hora subestatus'
          emptyText='-'
          showTime
        />
        <FunctionField
          source='subStateChangeDate'
          label='Días en subestatus'
          emptyText='-'
          render={(record) =>
            record.subStateChangeDate
              ? differenceInDays(
                  new Date(),
                  new Date(record.subStateChangeDate)
                )
              : '-'
          }
        />
        <FunctionField
          label='Rating'
          emptyText='-'
          render={(record) =>
            record?.person ? (
              <TextField source='person.savedRating.rating' emptyText='-' />
            ) : (
              <TextField
                source='legalPerson.savedRating.rating'
                emptyText='-'
              />
            )
          }
        />
      </Datagrid>
    </CustomList>
  );

  const AdminList = () => (
    <CustomList
      bulkActionButtons={
        <LoanApplicationBulkActionButtons
          permissions={permissions}
          role={role}
        />
      }
      filters={<LoanApplicationFilter permissions={permissions} />}
      actions={
        <ListActions
          entity='loan-application'
          permissions={permissions}
          {...props}
        />
      }
      {...props}
    >
      <Datagrid
        rowClick='show'
        isRowSelectable={(record) =>
          (record?.referrerCode && !record?.referrer) ||
          ([State.Formalization, State.CreditAnalysis].includes(
            record?.state?.id
          ) &&
            [AuthRole.Admin].includes(role))
        }
      >
        <TextField source='id' />
        <FunctionField
          source='person'
          render={(record) =>
            record?.person ? (
              <FullNameField record={record} source='person' entity='person' />
            ) : (
              <TextField source='legalPerson.businessName' emptyText='-' />
            )
          }
        />
        <FunctionField
          source='analyst'
          emptyText='-'
          render={(record) =>
            record.analyst
              ? `${record?.analyst?.code ?? ''} | ${
                  record?.analyst?.person?.firstName ?? ''
                } ${record?.analyst?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <NumberField
          source='amount'
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField source='months' />
        <FunctionField
          source='interestRate'
          emptyText='-'
          render={(record) =>
            record.interestRate ? `${record.interestRate}%` : '-'
          }
        />
        <ChipField source='applicationType.name' size='small' />
        <ChipField source='state.name' size='small' />
        <ChipField source='substate.name' emptyText='-' size='small' />
        <FunctionField
          source='stateChangeDate'
          label='Días en bandeja'
          emptyText='-'
          render={(record) =>
            record.stateChangeDate
              ? differenceInDays(new Date(), new Date(record.stateChangeDate))
              : '-'
          }
        />
        <FunctionField
          source='subStateChangeDate'
          label='Días en subestatus'
          emptyText='-'
          render={(record) =>
            record.subStateChangeDate
              ? differenceInDays(
                  new Date(),
                  new Date(record.subStateChangeDate)
                )
              : '-'
          }
        />
        <FunctionField
          source='created_at'
          label='Días de creación'
          emptyText='-'
          render={(record) =>
            record.created_at
              ? differenceInDays(new Date(), new Date(record.created_at))
              : '-'
          }
        />
        <DateField source='stateChangeDate' locales='es-MX' emptyText='-' />
        <DateField source='updated_at' locales='es-MX' />
        <DateField source='created_at' locales='es-MX' />
      </Datagrid>
    </CustomList>
  );

  const ControlDeskList = () => (
    <CustomList
      filters={<LoanApplicationFilter permissions={permissions} />}
      actions={
        <ListActions
          entity='loan-application'
          permissions={permissions}
          {...props}
        />
      }
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' />
        {role !== AuthRole.HumanResource && (
          <FunctionField
            source='person'
            render={(record) =>
              record?.person ? (
                <FullNameField
                  record={record}
                  source='person'
                  entity='person'
                />
              ) : (
                <TextField source='legalPerson.businessName' emptyText='-' />
              )
            }
          />
        )}
        <NumberField
          source='amount'
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField source='months' />
        <FunctionField
          source='interestRate'
          emptyText='-'
          render={(record) =>
            record.interestRate ? `${record.interestRate}%` : '-'
          }
        />
        <ChipField source='applicationType.name' size='small' />
        <ChipField source='state.name' size='small' />
        <ChipField source='substate.name' emptyText='-' size='small' />
        <FunctionField
          source='stateChangeDate'
          label='Horas en bandeja'
          emptyText='-'
          render={(record) =>
            record.stateChangeDate
              ? differenceInHours(new Date(), new Date(record.stateChangeDate))
              : '-'
          }
        />
        <FunctionField
          source='stateChangeDate'
          label='Días en bandeja'
          emptyText='-'
          render={(record) =>
            record.stateChangeDate
              ? differenceInDays(new Date(), new Date(record.stateChangeDate))
              : '-'
          }
        />
        <FunctionField
          source='subStateChangeDate'
          label='Días en subestatus'
          emptyText='-'
          render={(record) =>
            record.subStateChangeDate
              ? differenceInDays(
                  new Date(),
                  new Date(record.subStateChangeDate)
                )
              : '-'
          }
        />
        <FunctionField
          source='created_at'
          label='Días de creación'
          emptyText='-'
          render={(record) =>
            record.created_at
              ? differenceInDays(new Date(), new Date(record.created_at))
              : '-'
          }
        />
        <DateField source='stateChangeDate' locales='es-MX' emptyText='-' />
        <DateField source='updated_at' locales='es-MX' />
        <DateField source='created_at' locales='es-MX' />
      </Datagrid>
    </CustomList>
  );

  const GeneralList = () => (
    <CustomList
      filters={<LoanApplicationFilter permissions={permissions} />}
      actions={
        <ListActions
          entity='loan-application'
          permissions={permissions}
          {...props}
        />
      }
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' />
        {role !== AuthRole.HumanResource && (
          <FunctionField
            source='person'
            render={(record) =>
              record?.person ? (
                <FullNameField
                  record={record}
                  source='person'
                  entity='person'
                />
              ) : (
                <TextField source='legalPerson.businessName' emptyText='-' />
              )
            }
          />
        )}
        {[AuthRole.Lead, AuthRole.Audit].includes(role) && (
          <FunctionField
            source='analyst'
            emptyText='-'
            render={(record) =>
              record.analyst
                ? `${record?.analyst?.code ?? ''} | ${
                    record?.analyst?.person?.firstName ?? ''
                  } ${record?.analyst?.person?.fatherLastName ?? ''}`
                : '-'
            }
          />
        )}
        <NumberField
          source='amount'
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField source='months' />
        <FunctionField
          source='interestRate'
          emptyText='-'
          render={(record) =>
            record.interestRate ? `${record.interestRate}%` : '-'
          }
        />
        <ChipField source='applicationType.name' size='small' />
        <ChipField source='state.name' size='small' />
        <ChipField source='substate.name' emptyText='-' size='small' />
        <FunctionField
          source='stateChangeDate'
          label='Días en bandeja'
          emptyText='-'
          render={(record) =>
            record.stateChangeDate
              ? differenceInDays(new Date(), new Date(record.stateChangeDate))
              : '-'
          }
        />
        <FunctionField
          source='subStateChangeDate'
          label='Días en subestatus'
          emptyText='-'
          render={(record) =>
            record.subStateChangeDate
              ? differenceInDays(
                  new Date(),
                  new Date(record.subStateChangeDate)
                )
              : '-'
          }
        />
        <FunctionField
          source='created_at'
          label='Días de creación'
          emptyText='-'
          render={(record) =>
            record.created_at
              ? differenceInDays(new Date(), new Date(record.created_at))
              : '-'
          }
        />
        <DateField source='stateChangeDate' locales='es-MX' emptyText='-' />
        <DateField source='updated_at' locales='es-MX' />
        <DateField source='created_at' locales='es-MX' />
      </Datagrid>
    </CustomList>
  );

  switch (role) {
    case AuthRole.Risk:
      return <RiskList />;
    case AuthRole.Formalization:
      return <FormalizationList />;
    case AuthRole.Admin:
    case AuthRole.CommercialLink:
      return <AdminList />;
    case AuthRole.ControlDesk:
      return <ControlDeskList />;
    default:
      return <GeneralList />;
  }
};

export default LoanApplicationList;
