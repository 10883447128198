import React from 'react';
import type { FC } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import { SelectInput, FormWithRedirect } from 'react-admin';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import type { Theme } from '../../../theme';
import { formatNumber } from '../../../Utils/functions';
import Page from '../../../Components/Page';
import InformationCard from '../Components/InformationCard';
import BarGraphic from '../Components/Graphics/Bar';
import Header from '../Components/Header';
import Table from '../Components/Table';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  card: {
    paddingInline: 5,
  },
}));

const columnsByType = [
  {
    field: 'applicationType',
    headerName: 'Producto',
    editable: false,
    width: 170,
  },
  {
    field: 'quantity',
    headerName: 'Cantidad',
    type: 'number',
    editable: false,
    width: 135,
  },
  {
    field: 'amount',
    headerName: 'Monto',
    type: 'number',
    editable: false,
    width: 225,
    valueFormatter: (params) => {
      const valueFormatted = formatNumber(params.value);
      return `MXN ${valueFormatted}`;
    },
  },
];

const columnsByZone = [
  {
    field: 'teamZone',
    headerName: 'Zona/Equipo',
    editable: false,
    width: 170,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'quantity',
    headerName: 'Cantidad',
    type: 'number',
    editable: false,
    width: 135,
  },
  {
    field: 'amount',
    headerName: 'Monto',
    type: 'number',
    editable: false,
    width: 225,
    valueFormatter: (params) => {
      const valueFormatted = formatNumber(params.value);
      return `MXN ${valueFormatted}`;
    },
  },
];

const DashboardAdmin: FC<any> = ({ record, getDashboard }: any) => {
  const classes = useStyles();

  let totalLoan = 0;
  let totalAmount = 0;
  const stats = { amount: [], quantity: [] };
  const labels = [];

  for (let res of record.dashboardGeneralInfo) {
    totalLoan += parseInt(res.quantity);
    totalAmount += parseInt(res.amount);

    labels.push(res.state);
    stats.amount.push(parseInt(res.amount));
    stats.quantity.push(parseInt(res.quantity));
  }

  return (
    <Page className={classes.root} title='IDEXI'>
      <Container maxWidth='lg'>
        <Header altText='solicitudes' />
        <Grid container spacing={3}>
          <Grid item lg={4} sm={4} xs={12}>
            <FormWithRedirect
              render={() => (
                <SelectInput
                  fullWidth
                  allowEmpty
                  source='zone'
                  label='Zona/Equipo'
                  resource='dashboard'
                  optionText='teamZone'
                  optionValue='departmentId'
                  emptyText='Vista general'
                  choices={record.departmentsInfo.map((department) => ({
                    departmentId: department.departmentId ?? 'NULL',
                    teamZone: department.teamZone ?? 'N/A',
                  }))}
                  onChange={(e) => getDashboard(e.target.value)}
                  variant='outlined'
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xs={12} />
          <Grid item lg={6} sm={6} xs={12}>
            <InformationCard
              tittle='Solicitudes'
              amount={totalLoan}
              icon={<FolderOpenIcon />}
            />
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <InformationCard
              tittle='Monto'
              amount={totalAmount}
              icon={<AttachMoneyIcon />}
              background
              isAmount
            />
          </Grid>
          <Grid item xs={12}>
            <BarGraphic stats={stats} labels={labels} />
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardHeader title='Colocación por tipo' />
              <Divider />
              <CardContent className={classes.card}>
                <Table
                  rows={record.dashboardByApplicationType}
                  columns={columnsByType}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardHeader title='Colocación por zona' />
              <Divider />
              <CardContent className={classes.card}>
                <Table rows={record.dashboardTotals} columns={columnsByZone} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardAdmin;
