import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import Person from './Segment/Person';
import LoanApplication from './Segment/LoanApplication';
import Others from './Segment/Others';
import { AuthRole } from '../../Utils/enums';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '20px 0px',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  list: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > li': {
        flexBasis: '33.33%',
      },
    },
  },
  bold: {
    fontWeight: 'bold',
    marginBottom: '30px',
  },
}));

const SettingsMaintenances = () => {
  const classes = useStyles();
  const iconColor = 'rgb(144, 162, 219)';
  const user = JSON.parse(localStorage.getItem('user'));
  if (
    user.role.id !== AuthRole.CommandCenter &&
    user.role.id !== AuthRole.Admin
  ) {
    window.location.href = `/#/403`;
  }

  return (
    <Container className={classes.container} maxWidth='lg'>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography gutterBottom variant='overline'>
            Configuración
          </Typography>
          <Typography variant='h1' className={classes.bold}>
            Mantenimientos
          </Typography>
        </Grid>
      </Grid>
      <Person classes={classes} iconColor={iconColor} />
      <LoanApplication classes={classes} iconColor={iconColor} />
      <Others classes={classes} iconColor={iconColor} />
    </Container>
  );
};

export default SettingsMaintenances;
