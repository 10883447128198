import {
  FormWithRedirect,
  required,
  SaveButton,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { Box, Toolbar, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useState } from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
  })
);

interface PositionInterface {
  props?: object;
}

const isRequired = [required()];

export const PositionForm: React.FC<PositionInterface> = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState<any>();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { company } = user;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        if (!values) setValues(formProps.record);

        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ReferenceInput
                      source={
                        formProps.record?.id ? 'department.id' : 'department'
                      }
                      resource='positions'
                      reference='departments'
                      fullWidth
                      validate={isRequired}
                      disabled={!company}
                      onChange={() => {
                        setValues(formProps.form.getState().values);
                        formProps.form.change('supervisor', '');
                      }}
                      filter={
                        company?.id
                          ? {
                              company: company?.id,
                            }
                          : {
                              company,
                            }
                      }
                      variant='outlined'
                    >
                      <AutocompleteInput optionText='name' />
                    </ReferenceInput>
                    <TextInput
                      fullWidth
                      source='name'
                      resource='positions'
                      validate={isRequired}
                      variant='outlined'
                    />
                    <ReferenceInput
                      source={
                        formProps.record?.id ? 'supervisor.id' : 'supervisor'
                      }
                      resource='positions'
                      reference='positions'
                      disabled={!values?.department}
                      fullWidth
                      variant='outlined'
                      filter={
                        values?.department && {
                          department: formProps.record.id
                            ? values.department.id
                            : values.department,
                        }
                      }
                    >
                      <SelectInput />
                    </ReferenceInput>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <SaveButton
                  saving={formProps.saving}
                  disabled={formProps.invalid}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

export default PositionForm;
