import { Grid } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CheckIcon from '@material-ui/icons/Check';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import InformationCard from '../../Components/InformationCard';
import { SegmentState } from '../../../../Utils/enums';

const CardsSegment = ({ record }) => {
  const totalQuantity = {
    document: 0,
    validation: 0,
    disbursement: 0,
  };

  for (let actual of record) {
    switch (actual.segment) {
      case SegmentState.segmentState2:
        totalQuantity.document += parseInt(actual.quantity);
        break;
      case SegmentState.segmentState3:
        totalQuantity.validation += parseInt(actual.quantity);
        break;
      case SegmentState.segmentState4:
        totalQuantity.disbursement += parseInt(actual.quantity);
        break;
      default:
        break;
    }
  }
  return (
    <>
      <Grid item lg={4} sm={6} xs={12}>
        <InformationCard
          tittle='Documentación'
          amount={totalQuantity.document}
          icon={<FindInPageIcon />}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <InformationCard
          tittle='Validación'
          amount={totalQuantity.validation}
          icon={<CheckIcon />}
        />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <InformationCard
          tittle='Desembolso'
          amount={totalQuantity.disbursement}
          icon={<AccountBalanceWalletIcon />}
        />
      </Grid>
    </>
  );
};

export default CardsSegment;
