import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
    '& div': {
      marginBottom: '10px',
    },
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '130px',
    fontWeight: 'bold',
    margin: '0px',
  },
  successBG: {
    color: '#fff',
    backgroundColor: '#43d54a',
    padding: '5px 10px',
  },
  errorBG: {
    color: '#fff',
    backgroundColor: '#d32f2f',
    padding: '5px 10px',
  },
  error: {
    color: '#d32f2f',
  },
  warning: {
    color: 'rgb(211, 145, 47)',
  },
}));

const messageType = (message, index, classes) => {
  switch (message.type) {
    case 'error':
      return (
        <p className={classes.error} key={index}>
          {message.text}
        </p>
      );
    case 'warning':
      return (
        <p className={classes.warning} key={index}>
          {message.text}
        </p>
      );
    default:
      break;
  }
};
const RatingValue = ({ ratingData, record }) => {
  const classes = useStyles();
  const personType = record?.person ? 'person' : 'legalPerson';

  if (!ratingData && !record?.[personType]?.savedRating) {
    return (
      <div className={classes.root}>
        <p className={classes.title}>N/A</p>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {ratingData ? (
        <>
          <p className={classes.title}>{ratingData.rating}</p>
          <div>
            <span>RATING: </span>
            <span>
              {Number(ratingData.ratingValue) > 0
                ? ratingData.ratingValue
                : '-'}
            </span>
          </div>
          <div>
            <span>DICTAMEN: </span>
            <span
              className={
                ratingData.isRatingApproved
                  ? classes.successBG
                  : classes.errorBG
              }
            >
              {ratingData?.isRatingApproved ? 'Aceptado' : 'Rechazado'}
            </span>
          </div>
          <div>
            {ratingData?.errorsMessages.map((message, index) =>
              messageType(message, index, classes)
            )}
          </div>
        </>
      ) : (
        <>
          <p className={classes.title}>
            {record?.[personType]?.savedRating.rating}
          </p>
          <div>
            <span>RATING: </span>
            <span>
              {Number(record?.[personType]?.savedRating.ratingValue) > 0
                ? record?.[personType]?.savedRating.ratingValue
                : '-'}
            </span>
          </div>
          <div>
            <span>DICTAMEN: </span>
            <span
              className={
                record?.[personType]?.savedRating.isRatingApproved
                  ? classes.successBG
                  : classes.errorBG
              }
            >
              {record?.[personType]?.savedRating?.isRatingApproved
                ? 'Aceptado'
                : 'Rechazado'}
            </span>
          </div>
          <div>
            {record?.[personType]?.savedRating?.errorsMessages.map(
              (message, index) => messageType(message, index, classes)
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RatingValue;
