import React from 'react';
import {
  Datagrid,
  TextField,
  useTranslate,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

import ContactField from '../../Components/ContactField';
import FullNameField from '../../Components/FullNameField';
import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';
import AgentAssignButton from './AgentAssignButton';
import AgentUnassignButton from './AgentUnassignButton';
import { AuthRole } from '../../Utils/enums';

const useStyles = makeStyles({
  small: {
    width: '120px',
  },
  medium: {
    width: '200px',
  },
});

const PersonBulkActionButtons = (props) => (
  <>
    <AgentAssignButton {...props} />
    <AgentUnassignButton {...props} />
  </>
);

const PersonFilter = (props) => {
  const classes = useStyles(props);

  return (
    <Filter {...props} variant='outlined'>
      <TextInput
        source='id'
        label='Id'
        className={classes.small}
        resettable
        alwaysOn
      />
      <TextInput
        source='q'
        label='resources.people.fields.firstName'
        className={classes.medium}
        resettable
        alwaysOn
      />
      <TextInput
        source='documentNumber'
        label='resources.people.fields.documentNumber'
        resettable
      />
      <ReferenceInput
        reference='employees'
        className={classes.medium}
        source='agent'
        label='resources.people.fields.agent'
        resettable
      >
        <AutocompleteInput
          optionText={(record) =>
            record.id &&
            `${record?.code || ''} | ${record.person?.firstName ?? ''} ${
              record.person?.fatherLastName ?? ''
            }`
          }
        />
      </ReferenceInput>
    </Filter>
  );
};

export const PersonList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const role = JSON.parse(localStorage.getItem('user')).role.id;

  return (
    <CustomList
      filters={<PersonFilter />}
      bulkActionButtons={
        (role === AuthRole.CommercialLink || role === AuthRole.Admin) && (
          <PersonBulkActionButtons />
        )
      }
      actions={
        <ListActions entity='person' permissions={permissions} {...props} />
      }
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' />
        <FullNameField source='firstName' />
        <TextField source='documentNumber' />
        <FunctionField
          source='agent'
          emptyText='-'
          render={(record) =>
            record.agent
              ? `${record?.agent?.code ?? ''} | ${
                  record?.agent?.person?.firstName ?? ''
                } ${record?.agent?.person?.fatherLastName ?? ''}`
              : '-'
          }
        />
        <ContactField
          label={translate('resources.people.fields.cellPhone')}
          source='contacts'
          contactType='Celular'
        />
      </Datagrid>
    </CustomList>
  );
};

export default PersonList;
