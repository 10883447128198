import React from 'react';
import {
  Datagrid,
  TextField,
  FunctionField,
  ShowButton,
  Filter,
  ReferenceInput,
  SelectInput,
  useRedirect,
} from 'react-admin';

import CustomList from '../../Components/CustomList';
import GuaranteePanelShow from './GuaranteePanelShow';
import StateAssignButton from './StateAssignButton';
import { StateType } from '../../Utils/enums';
import { savePreviousPath } from '../../Utils/functions';

const GuaranteesFilter = (props) => (
  <Filter {...props} variant='outlined'>
    <ReferenceInput
      alwaysOn
      resettable
      label='Estado'
      reference='states'
      source='assetState'
      filter={{ stateTypeId: StateType.Assets }}
    >
      <SelectInput />
    </ReferenceInput>
  </Filter>
);

const GuaranteePanelList = ({ permissions, ...props }) => {
  const redirect = useRedirect();
  return (
    <CustomList
      bulkActionButtons={<StateAssignButton {...props} />}
      actions={<></>}
      filters={<GuaranteesFilter />}
      {...props}
    >
      <Datagrid expand={<GuaranteePanelShow />}>
        <TextField source='id' emptyText='-' label='Id' />
        <TextField
          source='assetTimesUsed'
          emptyText='-'
          label='Cantidad de solicitudes'
        />
        <TextField source='assetType' emptyText='-' label='Tipo de bien' />
        <FunctionField
          label='Dueño'
          render={(record) =>
            record?.assetInfo[0].assetPersonOwner ? (
              <TextField source='assetInfo[0].assetPersonOwner' emptyText='-' />
            ) : (
              <TextField
                source='assetInfo[0].assetLegalPersonOwner'
                emptyText='-'
              />
            )
          }
        />
        <TextField source='assetState' label='Estado' emptyText='-' />
        <TextField
          source='assetInfo[0].assetStatalUbication'
          emptyText='-'
          label='Ubicación estatal'
        />
        <FunctionField
          render={(record) => (
            <>
              <ShowButton
                label=''
                onClick={() => {
                  savePreviousPath(window.location.href);
                  redirect(`/assets/${record?.id}/show`);
                }}
              />
            </>
          )}
        />
      </Datagrid>
    </CustomList>
  );
};

export default GuaranteePanelList;
