import React, { useState } from 'react';
import {
  AutocompleteInput,
  FormWithRedirect,
  NumberInput,
  ReferenceInput,
  required,
  maxValue,
  SaveButton,
  SelectInput,
  useTranslate,
  TextInput,
} from 'react-admin';
import { Grid, Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  ApplicationSource,
  ApplicationType,
  AuthRole,
  Company,
  monthsP2P,
} from '../../Utils/enums';
import { formatInputNumber, parseInputNumber } from '../../Utils/functions';
import Typography from '@material-ui/core/Typography';
import FormatNumberInput from '../../Components/FormatNumberInput';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    mr: {
      marginRight: '5px',
    },
    mt: {
      marginTop: 'inherit',
    },
  })
);
const LoanApplicationForm = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const roleId = user.role?.id;
  const { person, legalPerson } = props;
  const translate = useTranslate();
  const [values, setValues] = useState<any>();
  const [applicationType, setApplicationType] = useState<number>();

  const record = (props as any).record || {};
  const isNew = !record?.id;
  const company =
    (isNew ? user.company?.id : record.company?.id) || Company.IbanOnlineMx;

  return (
    <FormWithRedirect
      {...props}
      initialValues={{
        person,
        legalPerson,
        applicationSource: ApplicationSource.CRM,
      }}
      render={(formProps) => {
        return (
          <form>
            <Grid container spacing={1} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                classes={{ root: classes.mr }}
              >
                {person || formProps?.record?.person ? (
                  <ReferenceInput
                    fullWidth
                    source='person.id'
                    resource='loan-applications'
                    reference='people'
                    variant='outlined'
                    validate={[required()]}
                    defaultValue={person ? person : formProps?.record?.person}
                  >
                    <AutocompleteInput
                      optionText={(record) =>
                        record &&
                        `${record.firstName} ${record.fatherLastName ?? ''} – ${
                          record.documentNumber
                        }`
                      }
                    />
                  </ReferenceInput>
                ) : legalPerson || formProps?.record?.legalPerson ? (
                  <ReferenceInput
                    fullWidth
                    source='legalPerson.id'
                    resource='loan-applications'
                    reference='legal-people'
                    variant='outlined'
                    validate={[required()]}
                    defaultValue={
                      legalPerson ? legalPerson : formProps?.record?.legalPerson
                    }
                  >
                    <AutocompleteInput
                      optionText={(record) =>
                        record &&
                        `${record.documentNumber} - ${record.businessName}`
                      }
                    />
                  </ReferenceInput>
                ) : (
                  <>
                    <Grid item xs={6} classes={{ root: classes.mt }}>
                      <SelectInput
                        source={
                          props.record?.id ? 'personType.id' : 'personType'
                        }
                        fullWidth
                        label={translate(
                          'resources.loan-applications.fields.personType'
                        )}
                        variant='outlined'
                        onChange={(e) => {
                          setValues(e.target.value);
                        }}
                        validate={[required()]}
                        choices={[
                          { id: 1, name: 'Persona física' },
                          { id: 2, name: 'Persona moral' },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6} classes={{ root: classes.mt }}>
                      {values === 2 ? (
                        <ReferenceInput
                          source={'legalPerson'}
                          reference='legal-people'
                          label={translate(
                            'resources.loan-applications.fields.legalPerson'
                          )}
                          validate={[required()]}
                          fullWidth
                          variant='outlined'
                        >
                          <AutocompleteInput
                            optionText={(record) =>
                              record &&
                              `${record.documentNumber} - ${record.businessName}`
                            }
                          />
                        </ReferenceInput>
                      ) : (
                        <ReferenceInput
                          source={'person'}
                          reference='people'
                          label={translate(
                            'resources.loan-applications.fields.person'
                          )}
                          validate={[required()]}
                          fullWidth
                          variant='outlined'
                        >
                          <AutocompleteInput
                            optionText={(record) =>
                              record &&
                              `${record.documentNumber} - ${record.firstName} ${record.fatherLastName}`
                            }
                          />
                        </ReferenceInput>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={4}>
                <ReferenceInput
                  fullWidth
                  source={
                    props.record?.id ? 'applicationType.id' : 'applicationType'
                  }
                  resource='loan-applications'
                  reference='application-types'
                  onChange={(e) => setApplicationType(e.target.value)}
                  variant='outlined'
                  validate={[required()]}
                  filter={{
                    company: company,
                  }}
                >
                  <SelectInput optionText='name' />
                </ReferenceInput>
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  fullWidth
                  source='amount'
                  defaultValue='0'
                  format={(v) => formatInputNumber(v)}
                  parse={(v) => parseInputNumber(v)}
                  resource='loan-applications'
                  variant='outlined'
                  validate={[required()]}
                />
              </Grid>
              <Grid item xs={4}>
                {applicationType === ApplicationType.P2P ||
                formProps.form.getState().values?.applicationType?.id ===
                  ApplicationType.P2P ? (
                  <SelectInput
                    fullWidth
                    source='months'
                    resource='loan-applications'
                    variant='outlined'
                    choices={monthsP2P}
                    validate={[
                      required(),
                      maxValue(120, 'La cantidad máxima es 120 meses'),
                    ]}
                  />
                ) : (
                  <NumberInput
                    fullWidth
                    source='months'
                    resource='loan-applications'
                    variant='outlined'
                    validate={[
                      required(),
                      maxValue(120, 'La cantidad máxima es 120 meses'),
                    ]}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <NumberInput
                  fullWidth
                  source='interestRate'
                  variant='outlined'
                  resource='loan-applications'
                />
              </Grid>
              {company === Company.IbanOnlineMx && (
                <Grid item xs={4}>
                  <ReferenceInput
                    fullWidth
                    source={
                      props.record?.id
                        ? 'applicationReason.id'
                        : 'applicationReason'
                    }
                    resource='loan-applications'
                    reference='application-reasons'
                    variant='outlined'
                    filter={{
                      company: company,
                    }}
                  >
                    <SelectInput optionText='name' />
                  </ReferenceInput>
                </Grid>
              )}
              <Grid item xs={4}>
                <ReferenceInput
                  fullWidth
                  source={props.record?.id ? 'clientSource.id' : 'clientSource'}
                  resource='loan-applications'
                  reference='client-sources'
                  variant='outlined'
                >
                  <SelectInput optionText='name' />
                </ReferenceInput>
              </Grid>
              {[
                AuthRole.Admin,
                AuthRole.Business,
                AuthRole.BusinessKap,
                AuthRole.ControlDesk,
                AuthRole.Formalization,
              ].includes(roleId) && (
                <Grid item xs={4}>
                  <ReferenceInput
                    fullWidth
                    source='fundingCompany.id'
                    resource='loan-applications'
                    reference='funding-companies'
                    variant='outlined'
                  >
                    <SelectInput optionText='name' />
                  </ReferenceInput>
                </Grid>
              )}
              {[AuthRole.Formalization, AuthRole.Admin].includes(roleId) && (
                <Grid item xs={4}>
                  <ReferenceInput
                    fullWidth
                    source={props.record?.id ? 'notary.id' : 'notary'}
                    resource='loan-applications'
                    reference='notaries'
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
              )}
              {[AuthRole.Formalization, AuthRole.Admin].includes(roleId) && (
                <Grid item xs={4}>
                  <ReferenceInput
                    fullWidth
                    source={
                      props.record?.id ? 'evaluationUnit.id' : 'evaluationUnit'
                    }
                    resource='loan-applications'
                    reference='evaluation-units'
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
              )}
              {[
                AuthRole.Risk,
                AuthRole.Formalization,
                AuthRole.CommercialLink,
                AuthRole.Admin,
              ].includes(roleId) && (
                <Grid item xs={4}>
                  <FormatNumberInput
                    source='appraisedValue'
                    resource='loan-applications'
                  />
                </Grid>
              )}
              {[AuthRole.Treasury, AuthRole.Admin, AuthRole.CAV].includes(
                roleId
              ) && (
                <Grid item xs={4}>
                  <TextInput
                    source='contractNumber'
                    resource='loan-applications'
                    variant='outlined'
                    disabled={props.record?.contractNumber}
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
              </Box>
            </Toolbar>
          </form>
        );
      }}
    />
  );
};
export default LoanApplicationForm;
