import {
  Button,
  DateInput,
  FormWithRedirect,
  required,
  SaveButton,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { Box, Toolbar, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { DocumentSegment } from '../../Utils/enums';
import ImageList from './ImageList';
import ImageUploadButton from './ImageUploadButton';
import { useState } from 'react';
import Cookies from '../../Utils/cookies';
import { changeMade, errors, thereWasProblem } from '../../Utils/messages';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  })
);

const isRequired = [required()];

export const PromotionForm: React.FC<any> = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [loading, setLoading] = useState<boolean>();
  const [event, setEvent] = useState<any>();

  const handleSubmit = (values) => {
    setLoading(true);

    const formData = new FormData();
    if (event.target.files.length === 1) {
      const file = event.target.files[0];
      formData.append(`files.documents`, file, file.name);
    } else {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        formData.append(`files.documents`, file, file.name);
      }
    }
    formData.append('data', JSON.stringify(values));

    const token = Cookies.getCookie('token');
    fetch(`${process.env.REACT_APP_API_URL}/promotions`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        const parsedData = await res.json();
        if (parsedData.statusCode === 403) {
          window.location.href = `/#/403`;
          return;
        }

        return parsedData;
      })
      .then((response) => {
        setLoading(false);

        if (response.statusCode === 400 || response.statusCode > 403) {
          const code = response.data?.errorCode;
          return notify(
            `${thereWasProblem}: ${
              errors[code] ||
              'Hubo un error con su solicitud, inténtelo mas tarde...'
            }`,
            'warning'
          );
        }

        notify(changeMade, 'info');
        redirect('list', props.basePath);
        return refresh();
      });
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      source='name'
                      resource='promotions'
                      validate={isRequired}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput
                      fullWidth
                      source='initialDate'
                      resource='promotions'
                      validate={isRequired}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DateInput
                      fullWidth
                      source='finalDate'
                      resource='promotions'
                      validate={isRequired}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
                {props?.record?.id ? (
                  <ImageList
                    record={props?.record}
                    segment={DocumentSegment.Promotion.name}
                  />
                ) : (
                  <ImageUploadButton setEvent={setEvent} />
                )}
              </Grid>
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                {props?.record?.id ? (
                  <SaveButton
                    saving={formProps.saving}
                    disabled={formProps.invalid}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                    redirect='list'
                  />
                ) : (
                  <Button
                    label='Guardar'
                    variant='contained'
                    disabled={
                      loading ||
                      formProps.invalid ||
                      (!props.record?.id && !event)
                    }
                    onClick={() =>
                      handleSubmit(formProps.form.getState().values)
                    }
                  />
                )}
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

export default PromotionForm;
