import React, { useState } from 'react';
import type { FC } from 'react';
import {
  Container,
  makeStyles,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
} from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import type { Theme } from '../../../theme';
import { formatNumber } from '../../../Utils/functions';
import Page from '../../../Components/Page';
import InformationCard from '../Components/InformationCard';
import BarGraphic from '../Components/Graphics/Bar';
import Header from '../Components/Header';
import Table from '../Components/Table';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  card: {
    paddingInline: 5,
  },
}));

const generalColumns = [
  {
    field: 'applicationType',
    headerName: 'Producto',
    editable: false,
    width: 150,
  },
  {
    field: 'quantity',
    headerName: 'Cantidad',
    type: 'number',
    editable: false,
    width: 135,
  },
  {
    field: 'amount',
    headerName: 'Monto',
    type: 'number',
    editable: false,
    width: 225,
    valueFormatter: (params) => {
      const valueFormatted = formatNumber(params.value);
      return `MXN ${valueFormatted}`;
    },
  },
];

const columnsSupervisor = [
  {
    field: 'employeeCode',
    headerName: 'Agente',
    editable: false,
    width: 125,
  },
  {
    field: 'agentState',
    headerName: 'Estado',
    editable: false,
    width: 130,
  },
  {
    field: 'quantity',
    headerName: 'Cantidad',
    type: 'number',
    editable: false,
    width: 135,
  },
  {
    field: 'amount',
    headerName: 'Monto total',
    type: 'number',
    editable: false,
    width: 225,
    valueFormatter: (params) => {
      const valueFormatted = formatNumber(params.value);
      return `MXN ${valueFormatted}`;
    },
  },
  {
    field: 'recibir_documentación_quantity',
    headerName: 'Cantidad (Recibir documentación)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'recibir_documentación_amount',
    headerName: 'Monto (Recibir documentación)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'mesa_de_control_quantity',
    headerName: 'Cantidad (Mesa de control)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'mesa_de_control_amount',
    headerName: 'Monto (Mesa de control)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'análisis_de_crédito_quantity',
    headerName: 'Cantidad (Análisis de crédito)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'análisis_de_crédito_amount',
    headerName: 'Monto (Análisis de crédito)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'cumplimiento_aml_quantity',
    headerName: 'Cantidad (Cumplimiento AML)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'cumplimiento_aml_amount',
    headerName: 'Monto (Cumplimiento AML)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'formalización_quantity',
    headerName: 'Cantidad (Formalización)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'formalización_amount',
    headerName: 'Monto (Formalización)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'pendiente_de_firma_quantity',
    headerName: 'Cantidad (Pendiente de firma)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'pendiente_de_firma_amount',
    headerName: 'Monto (Pendiente de firma)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'firmado_quantity',
    headerName: 'Cantidad (Firmado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'firmado_amount',
    headerName: 'Monto (Firmado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'desembolsado_quantity',
    headerName: 'Cantidad (Desembolsado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'desembolsado_amount',
    headerName: 'Monto (Desembolsado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'saldado_quantity',
    headerName: 'Cantidad (Saldado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'saldado_amount',
    headerName: 'Monto (Saldado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'cancelado_quantity',
    headerName: 'Cantidad (Cancelado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'cancelado_amount',
    headerName: 'Monto (Cancelado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
  {
    field: 'rechazado_quantity',
    headerName: 'Cantidad (Rechazado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value || '-';
    },
  },
  {
    field: 'rechazado_amount',
    headerName: 'Monto (Rechazado)',
    type: 'number',
    editable: false,
    width: 225,
    valueGetter: (params) => {
      return params.value ? `MXN ${formatNumber(params.value)}` : '-';
    },
  },
];

const SupervisorTable = ({
  classes,
  setEmployee,
  record: { dashboardByAgent, dashboardByAgentState },
}) => {
  const data = dashboardByAgent.map((agentData) => {
    const details = {};
    dashboardByAgentState.forEach((agent) => {
      if (agent.employeeCode === agentData.employeeCode) {
        details[
          `${agent.stateName.toLowerCase().replaceAll(' ', '_')}_quantity`
        ] = agent.quantity;
        details[
          `${agent.stateName.toLowerCase().replaceAll(' ', '_')}_amount`
        ] = agent.amount;
      }
    });

    return {
      ...agentData,
      ...details,
    };
  });

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title='Colocación por agente' />
        <Divider />
        <CardContent className={classes.card}>
          <Table
            rows={data}
            columns={columnsSupervisor}
            setEmployee={setEmployee}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

const DashboardAgent: FC<any> = ({ record }: any) => {
  const classes = useStyles();
  const [employee, setEmployee] = useState('');

  let totalLoan = 0;
  let totalAmount = 0;
  const stats = { amount: [], quantity: [] };
  const labels = [];

  if (employee) {
    for (let res of record.dashboardByAgentState) {
      if (employee === res.employeeCode) {
        totalLoan += parseInt(res.quantity);
        totalAmount += parseInt(res.amount);

        labels.push(res.stateName);
        stats.amount.push(parseInt(res.amount));
        stats.quantity.push(parseInt(res.quantity));
      }
    }
  } else {
    for (let res of record.dashboardGeneralInfo) {
      totalLoan += parseInt(res.quantity);
      totalAmount += parseInt(res.amount);

      labels.push(res.state);
      stats.amount.push(parseInt(res.amount));
      stats.quantity.push(parseInt(res.quantity));
    }
  }

  if (record?.dashboardGestor)
    generalColumns.splice(1, 0, {
      field: 'state',
      headerName: 'Estado',
      editable: false,
      width: 200,
    });

  return (
    <Page className={classes.root} title='IDEXI'>
      <Container maxWidth='lg'>
        <Header altText='solicitudes' />
        <Grid container spacing={3}>
          {record?.franchiseQty || record?.franchiseQty === 0 ? (
            <Grid
              item
              lg={record?.franchiseQty || record?.franchiseQty === 0 ? 4 : 6}
              sm={6}
              xs={12}
            >
              <InformationCard
                tittle='Franquicias'
                amount={record.franchiseQty}
                icon={<FolderOpenIcon />}
              />
            </Grid>
          ) : null}
          <Grid
            item
            lg={record?.franchiseQty || record?.franchiseQty === 0 ? 4 : 6}
            sm={6}
            xs={12}
          >
            <InformationCard
              tittle='Solicitudes'
              amount={totalLoan}
              icon={<FolderOpenIcon />}
            />
          </Grid>
          <Grid
            item
            lg={record?.franchiseQty || record?.franchiseQty === 0 ? 4 : 6}
            sm={6}
            xs={12}
          >
            <InformationCard
              tittle='Monto'
              amount={totalAmount}
              icon={<AttachMoneyIcon />}
              background
              isAmount
            />
          </Grid>
          <Grid item xs={12}>
            <BarGraphic stats={stats} labels={labels} />
          </Grid>
          {record.dashboardByAgent && (
            <SupervisorTable
              record={record}
              classes={classes}
              setEmployee={setEmployee}
            />
          )}
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={
                  record?.dashboardByAgent
                    ? 'Colocación por tipo'
                    : 'Resumen de solicitudes'
                }
              />
              <Divider />
              <CardContent className={classes.card}>
                <Table
                  rows={
                    record?.dashboardByAgent
                      ? record.dashboardByApplicationType
                      : record?.dashboardGestor
                  }
                  columns={generalColumns}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardAgent;
