import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  DateField,
  EditButton,
  ListButton,
  ReferenceField,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  DeleteWithConfirmButton,
  ReferenceManyField,
  FunctionField,
  TopToolbar,
  Button,
  useTranslate,
  ChipField,
  NumberField,
  BooleanField,
  useNotify,
  useGetOne,
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  withStyles,
  Link as MuiLink,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import {
  AuthRole,
  Company,
  DocumentSegment,
  RepresentativeTypeNames,
  State,
  UnykooState,
  entities,
  personType,
} from '../../Utils/enums';
import { errors, thereWasProblem } from '../../Utils/messages';
import { DocumentUploadButton } from '../Documents';
import { DocumentList } from '../Documents';
import { FormShow } from '../../styles';
import FormTitle from '../../Components/FormTitle';
import FullNameField from '../../Components/FullNameField';
import MoralPersonNipButton from './MoralPersonNipButton';
import CreditCircleRetry from '../../Components/CreditCircleRetry';
import ResendNipButton from '../../Components/ResendNipButton';
import TablePlaceholder from '../../Components/TablePlaceholder';
import ReferenceShow from '../References/ReferenceShow';
import CustomDateInput from '../../Components/CustomDateInput';
import CIECRequestButton from './CIECRequestButton';
import { Timeline } from '../../Components/Timeline';
import { deletePreviousPath } from '../../Utils/functions';

const styles = {
  button: {
    marginTop: '1em',
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    letterSpacing: '1px',
    color: theme.palette.text.secondary,
    padding: '0 0.2rem 0.2rem',
    borderRadius: 4,
    borderBottom: `${theme.palette.divider} 1px solid`,
    fontSize: '1rem',
  },
  guaranteed: {
    color: theme.palette.text.secondary,
    alignSelf: 'center',
  },
}));

const AddNewButton = withStyles(styles)(
  ({ classes, record, label, path }: any) => (
    <Button
      className={classes.button}
      component={Link}
      to={{
        pathname: `/${path}/create`,
        search: `?legal-person=${record.id}`,
      }}
      label={label}
    >
      <AddCircleOutlineIcon />
    </Button>
  )
);

const MoralPersonActions = ({ basePath, data, permissions }: any) => {
  const classes = useStyles();
  const role = JSON.parse(localStorage.getItem('user') || '{}')?.role?.id;
  const validateEdit =
    role === AuthRole.Admin ||
    (permissions?.['legal-people']?.update?.enabled &&
      (!data?.loan_applications?.length ||
        data?.loan_applications?.some(
          (la) =>
            la.state === State.ReceiveDocumentation ||
            la.state === State.CreditAnalysis ||
            la.state === State.ReceiveDocumentationKap ||
            la.state === State.CreditAnalysisKap
        )));

  return (
    <TopToolbar
      style={{
        justifyContent: `${
          data?.agent || data?.guaranteed ? 'space-between' : 'flex-end'
        }`,
      }}
    >
      {data?.agent && (
        <Typography component='div' variant='h6' className={classes.title}>
          {`${data?.agent?.code ?? ''} | ${
            data?.agent?.person?.firstName ?? ''
          } ${data?.agent?.person?.fatherLastName ?? ''}`}
        </Typography>
      )}
      {data?.guaranteed && (
        <Typography component='div' variant='h6' className={classes.guaranteed}>
          {`Esta persona moral se encuentra como garante de la ${
            personType[data?.guaranteed?.entity]
          } ${data?.guaranteed?.id}`}
        </Typography>
      )}
      <div>
        {permissions?.['legal-people']?.requestciec?.enabled && (
          <CIECRequestButton legalPerson={data?.id} />
        )}
        <ListButton
          basePath={basePath}
          label='ra.action.back'
          icon={<ChevronLeft />}
          component={Link}
          to={basePath}
        />
        {validateEdit && <EditButton to={`${basePath}/${data?.id}`} />}
      </div>
    </TopToolbar>
  );
};

export const MoralPersonShow = ({ permissions, basePath, ...props }) => {
  const classes = FormShow();
  const translate = useTranslate();
  const notify = useNotify();
  const tab = props.location.pathname.split('show/')[1];
  const { data } = useGetOne('legal-people', props.id);
  const company = (data as any)?.company || Company.IbanOnlineMx;

  return (
    <Show
      basePath={basePath}
      {...props}
      actions={<MoralPersonActions permissions={permissions} />}
      aside={
        tab !== 'incomes' && tab !== 'assets' ? (
          <Timeline segment='legalPerson' />
        ) : (
          <></>
        )
      }
    >
      <TabbedShowLayout className={classes.borderBottom}>
        {renderGeneral()}
        {renderApplications()}
        {renderCC()}
        {renderDocuments()}
        {renderReferences()}
        {renderRelateds()}
        {renderIncomes()}
        {renderAssets()}
      </TabbedShowLayout>
    </Show>
  );

  function renderDocuments() {
    return (
      <Tab label='Documentos' path='documents'>
        <DocumentUploadButton
          documentSegment={DocumentSegment.Business.id}
          segment={DocumentSegment.Business.name}
          related={props.id}
        />
        <DocumentList segment={DocumentSegment.Business.name} />
      </Tab>
    );
  }

  function renderApplications() {
    return (
      <Tab label='Solicitudes' path='loan-applications'>
        <AddNewButton label='Crear una solicitud' path='loan-applications' />
        <ReferenceManyField
          className={classes.width100}
          reference='loan-applications'
          addLabel={false}
          target='legalPerson'
          filter={{ legalPerson: props.id }}
        >
          <Datagrid
            rowClick='show'
            empty={<TablePlaceholder title='Solicitudes' />}
          >
            <TextField source='id' />
            <NumberField
              source='amount'
              emptyText='-'
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <TextField source='months' emptyText='-' />
            <FunctionField
              source='interestRate'
              emptyText='-'
              render={(record) =>
                record.interestRate ? `${record.interestRate}%` : '-'
              }
            />
            <ReferenceField
              source='state.id'
              resource='people'
              reference='states'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <ChipField source='name' emptyText='-' />
            </ReferenceField>
            <ReferenceField
              source='applicationType.id'
              resource='people'
              reference='application-types'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <ChipField source='name' />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderAssets() {
    deletePreviousPath();
    return (
      <Tab label='Bienes' path='assets'>
        <AddNewButton label='Crear bien' path='assets' />
        <ReferenceManyField
          className={classes.width100}
          reference='assets'
          addLabel={false}
          target='person'
          filter={{ legalPerson: props.id }}
        >
          <Datagrid rowClick='show' empty={<TablePlaceholder title='Bienes' />}>
            <ReferenceField
              source='assetType'
              resource='people'
              reference='asset-types'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <TextField source='name' />
            </ReferenceField>
            <ReferenceField
              source='documentType'
              resource='people'
              reference='document-types'
              className={classes.width25}
              link={false}
              emptyText='-'
            >
              <ChipField source='name' />
            </ReferenceField>
            <TextField source='documentNumber' emptyText='-' />
            <NumberField
              emptyText='-'
              source='value'
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <BooleanField source='legalOpposition' emptyText='-' />
            {data?.loan_applications?.every(
              (la) =>
                la.state !== State.Disbursed || la.state !== State.DisbursedKap
            ) && (
              <DeleteWithConfirmButton
                redirect={false}
                label=''
                onFailure={(error) => {
                  const code = error.body.data.errorCode;
                  notify(
                    `${thereWasProblem}: ${
                      errors[code] ||
                      'Hubo un error con su solicitud, inténtelo mas tarde...'
                    }`,
                    'warning'
                  );
                }}
                confirmContent={translate(
                  'resources.assets.fields.confirmDelete'
                )}
                confirmTitle='Eliminar'
              />
            )}
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderCC() {
    return (
      <Tab label='Consulta BC' path='credit-inquiry-unykoos'>
        <AddNewButton label='Consulta BC' path='credit-inquiry-unykoos' />
        <ReferenceManyField
          addLabel={false}
          reference='credit-inquiry-unykoos'
          target='legalPerson'
          className={classes.width100}
          filter={{ legalPerson: props.id }}
        >
          <Datagrid empty={<TablePlaceholder title='Consultas de crédito' />}>
            <TextField source='unykoo' />
            <NumberField
              emptyText='-'
              source='amount'
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <DateField
              className={classes.width25}
              source='created_at'
              options={{
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              }}
              emptyText='-'
              showTime
            />
            <DateField
              className={classes.width25}
              source='requestDate'
              emptyText='-'
              showTime
            />
            <FunctionField
              render={(record) =>
                record.status === UnykooState.Progress ||
                record.status === UnykooState.Success ? (
                  record.error ? (
                    <ResendNipButton record={record} />
                  ) : (
                    <MoralPersonNipButton record={record} />
                  )
                ) : (
                  <CreditCircleRetry record={record} />
                )
              }
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderRelateds() {
    return (
      <Tab
        label='Relacionados'
        contentClassName={classes.flexParent}
        path='relateds'
      >
        <AddNewButton label='Agregar Relacionado' path='relateds' />
        <ReferenceManyField
          className={classes.width100}
          reference='relateds'
          addLabel={false}
          target='person'
          filter={{ legalPerson: props.id }}
        >
          <Datagrid empty={<TablePlaceholder title='Relacionados' />}>
            <TextField
              source='relatedType.name'
              emptyText='-'
              label={translate('resources.related.fields.relatedType')}
            />
            <FunctionField
              source='relatedPerson'
              label={translate('resources.related.fields.relatedPerson')}
              render={(record) =>
                record.relatedPerson ? (
                  <MuiLink
                    component={Link}
                    to={`/people/${record.relatedPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <FullNameField
                      record={record}
                      entity='relatedPerson'
                      source='person'
                      size={24}
                    />
                  </MuiLink>
                ) : (
                  '-'
                )
              }
            />
            <FunctionField
              source='relatedLegalPerson'
              label={translate('resources.related.fields.relatedLegalPerson')}
              render={(record) =>
                record.relatedLegalPerson ? (
                  <MuiLink
                    component={Link}
                    to={`/legal-people/${record.relatedLegalPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <TextField source='relatedLegalPerson.businessName' />
                  </MuiLink>
                ) : (
                  '-'
                )
              }
            />
            <FunctionField
              render={(record) => (
                <div style={{ textAlign: 'end' }}>
                  <EditButton
                    label=''
                    to={{
                      pathname: `/relateds/${record.id}`,
                      search: `?legal-person=${props.id}`,
                    }}
                  />
                  <DeleteWithConfirmButton
                    record={record}
                    label=''
                    redirect={false}
                    confirmContent={translate(
                      'resources.related.fields.confirmDelete'
                    )}
                    confirmTitle='Eliminar'
                  />
                </div>
              )}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderReferences() {
    return (
      <Tab
        label='Referencias'
        contentClassName={classes.flexParent}
        path='references'
      >
        <AddNewButton label='Agregar Referencia' path='references' />
        <ReferenceManyField
          className={classes.width100}
          reference='references'
          addLabel={false}
          target='person'
          filter={{ legalPerson: props.id }}
        >
          <Datagrid
            empty={<TablePlaceholder title='Referencias' />}
            expand={<ReferenceShow />}
          >
            <TextField label='Tipo' source='referenceType.name' />
            <TextField source='name' emptyText='-' />
            <TextField source='contacts[0].contact' emptyText='-' />
            <FunctionField
              addLabel={false}
              render={(record) => (
                <div style={{ textAlign: 'end' }}>
                  <EditButton
                    label=''
                    to={{
                      pathname: `/references/${record?.id}`,
                      search: `?legal-person=${props?.id}`,
                    }}
                  />
                  <DeleteWithConfirmButton
                    label=''
                    record={record}
                    redirect={false}
                    confirmContent={translate(
                      'resources.references.fields.confirmDelete'
                    )}
                    confirmTitle='Eliminar'
                  />
                </div>
              )}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderIncomes() {
    return (
      <Tab
        label='Ingresos'
        contentClassName={classes.flexParent}
        path='incomes'
      >
        <AddNewButton label='Agregar Ingreso' path='incomes' />
        <ReferenceManyField
          reference='incomes'
          target='legalPerson'
          addLabel={false}
          className={classes.width100}
          filter={{ legalPerson: props.id }}
        >
          <Datagrid
            rowClick='show'
            empty={<TablePlaceholder title='Ingresos' />}
          >
            <ReferenceField
              source='incomeType'
              resource='legalPeople'
              reference='income-types'
              className={classes.width25}
              link={false}
              emptyText='-'
              label={translate('resources.incomes.fields.incomeType')}
            >
              <TextField source='name' />
            </ReferenceField>
            <TextField
              source='companyName'
              emptyText='-'
              label={translate('resources.incomes.fields.companyName')}
            />
            <TextField
              source='position'
              emptyText='-'
              label={translate('resources.incomes.fields.position')}
            />
            <NumberField
              source='amount'
              emptyText='-'
              label={translate('resources.incomes.fields.incomes')}
              options={{ style: 'currency', currency: 'MXN' }}
            />
            <FunctionField
              source='effectiveDate'
              className={classes.width25}
              render={() => <CustomDateInput source='effectiveDate' />}
            />
            <DeleteWithConfirmButton
              label=''
              redirect={false}
              confirmContent={translate(
                'resources.incomes.fields.confirmDelete'
              )}
              confirmTitle='Eliminar'
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    );
  }

  function renderGeneral() {
    const role = JSON.parse(localStorage.getItem('user') || '{}').role.id;

    return (
      <Tab label='General' contentClassName={classes.flexParent}>
        <FormTitle title='Información Personal' className={classes.width100} />
        <ReferenceField
          source='documentType.id'
          resource='people'
          className={classes.width25}
          emptyText='-'
          label={translate('resources.legal-people.fields.documentType')}
          reference='document-types'
          link={false}
        >
          <TextField source='name' />
        </ReferenceField>
        <TextField
          source='documentNumber'
          resource='legal-people'
          emptyText='-'
          className={classes.width25}
        />
        <TextField
          source='businessName'
          resource='legal-people'
          emptyText='-'
          className={classes.width25}
        />
        <TextField
          source='tradeName'
          resource='legal-people'
          emptyText='-'
          className={classes.width25}
        />
        <TextField
          source='commercialRegister'
          resource='legal-people'
          emptyText='-'
          className={classes.width25}
        />
        <FunctionField
          source='constitutionDate'
          className={classes.width25}
          render={() => <CustomDateInput source='constitutionDate' />}
        />
        <FunctionField
          source='registerIssueDate'
          className={classes.width25}
          render={() => <CustomDateInput source='registerIssueDate' />}
        />
        <FunctionField
          source='registerExpirationDate'
          className={classes.width25}
          render={() => <CustomDateInput source='registerExpirationDate' />}
        />
        <ReferenceField
          source='constitutionCountry.id'
          reference='countries'
          emptyText='-'
          link={false}
          className={classes.width25}
        >
          <TextField source='name' />
        </ReferenceField>
        {[AuthRole.Admin, AuthRole.ControlDesk, AuthRole.Risk].includes(
          role
        ) && (
          <TextField
            source='ciec'
            resource='legal-people'
            emptyText='-'
            className={classes.width25}
          />
        )}
        <NumberField
          source='billingIncome'
          emptyText='-'
          className={classes.width25}
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField
          source='statementIncome'
          emptyText='-'
          className={classes.width25}
          options={{ style: 'currency', currency: 'MXN' }}
        />
        <NumberField
          source='financialInformationIncome'
          emptyText='-'
          className={classes.width25}
          options={{ style: 'currency', currency: 'MXN' }}
        />
        {company === Company.IbanOnlineMx && (
          <NumberField
            source='onboardingGuaranteeValue'
            emptyText='-'
            className={classes.width25}
            options={{ style: 'currency', currency: 'MXN' }}
          />
        )}
        {company === Company.IbanOnlineMx && (
          <TextField
            source='onboardingGuaranteeType'
            className={classes.width25}
            emptyText='-'
          />
        )}
        {company === Company.KapitalizerOnlineMX && (
          <BooleanField
            source='hasGuarantee'
            emptyText='-'
            className={classes.width25}
          />
        )}
        {company === Company.KapitalizerOnlineMX && (
          <BooleanField
            source='isGuaranteeOwner'
            emptyText='-'
            className={classes.width25}
          />
        )}
        <BooleanField
          source='blacklist'
          className={classes.width25}
          emptyText='-'
        />
        <FormTitle
          title={translate('resources.other-documents.fields.name')}
          className={classes.width100}
        />
        <ArrayField
          addLabel={false}
          source='otherDocuments'
          fieldKey='id'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Otros documentos' />}>
            <TextField
              emptyText='-'
              source='documentType.name'
              label={translate('resources.other-documents.fields.documentType')}
            />
            <TextField
              emptyText='-'
              source='documentNumber'
              label={translate(
                'resources.other-documents.fields.documentNumber'
              )}
            />
          </Datagrid>
        </ArrayField>
        <FormTitle
          title={translate('resources.contacts.fields.name')}
          className={classes.width100}
        />
        <ArrayField
          fieldKey='id'
          addLabel={false}
          label=''
          source='contacts'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Contactos' />}>
            <TextField
              emptyText='-'
              source='contactType.name'
              label={translate('resources.contacts.fields.contactType')}
            />
            <TextField
              emptyText='-'
              source='contact'
              label={translate('resources.contacts.fields.contact')}
            />
            <FunctionField
              emptyText='-'
              source='duplicate'
              label={translate('resources.contacts.fields.duplicate')}
              render={(record) =>
                record?.duplicate
                  ? `Este contacto esta registrado en la entidad ${
                      entities[record.duplicate.entity]
                    } ${record.duplicate.id}`
                  : '-'
              }
            />
          </Datagrid>
        </ArrayField>
        <FormTitle
          title={translate('resources.addresses.fields.name')}
          className={classes.width100}
        />
        <ArrayField
          fieldKey='id'
          addLabel={false}
          label=''
          source='addresses'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Direcciones' />}>
            <TextField
              emptyText='-'
              source='addressType.name'
              label={translate('resources.addresses.fields.addressType')}
            />
            <TextField
              emptyText='-'
              source='country.name'
              label={translate('resources.addresses.fields.country')}
            />
            <TextField
              emptyText='-'
              source='province.name'
              label={translate('resources.addresses.fields.province')}
            />
            <TextField
              emptyText='-'
              source='municipality.name'
              label={translate('resources.addresses.fields.municipality')}
            />
            <TextField
              emptyText='-'
              source='neighborhood.name'
              label={translate('resources.addresses.fields.neighborhood')}
            />
            <TextField
              emptyText='-'
              source='city.name'
              label={translate('resources.addresses.fields.city')}
            />
            <TextField
              emptyText='-'
              source='street'
              label={translate('resources.addresses.fields.street')}
            />
            <TextField
              emptyText='-'
              source='outsideNumber'
              label={translate('resources.addresses.fields.outsideNumber')}
            />
            <TextField
              emptyText='-'
              source='postalCode'
              label={translate('resources.addresses.fields.postalCode')}
            />
            <FunctionField
              emptyText='-'
              source='duplicate'
              label={translate('resources.contacts.fields.duplicate')}
              render={(record) =>
                record?.duplicate
                  ? `Esta dirección esta registrada en la entidad ${
                      entities[record.duplicate.entity]
                    } ${record.duplicate.id}`
                  : '-'
              }
            />
          </Datagrid>
        </ArrayField>
        <FormTitle title='Representantes' className={classes.width100} />
        <ArrayField
          fieldKey='id'
          addLabel={false}
          className={classes.width100}
          source='representatives'
        >
          <Datagrid empty={<TablePlaceholder title='Representantes' />}>
            <FunctionField
              source='firstName'
              render={(record) =>
                record.physicalPerson ? (
                  <MuiLink
                    component={Link}
                    to={`/people/${record.physicalPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <FullNameField
                      record={record}
                      entity='physicalPerson'
                      source='person'
                      size={24}
                    />
                  </MuiLink>
                ) : (
                  <MuiLink
                    component={Link}
                    to={`/legal-people/${record.legalPerson?.id}/show`}
                    variant='inherit'
                    color='textPrimary'
                    underline='none'
                  >
                    <TextField source='legalPerson.businessName' />
                  </MuiLink>
                )
              }
            />
            <FunctionField
              source='documentNumber'
              render={(record) =>
                record?.legalPerson ? (
                  <TextField
                    source='legalPerson.documentNumber'
                    emptyText='-'
                    label={translate('resources.people.fields.documentNumber')}
                  />
                ) : (
                  <TextField
                    source='physicalPerson.documentNumber'
                    emptyText='-'
                    label={translate('resources.people.fields.documentNumber')}
                  />
                )
              }
            />
            <FunctionField
              source='representativeType'
              emptyText='-'
              label='resources.representatives.fields.representativeType'
              render={(record) =>
                RepresentativeTypeNames[record?.representativeType]
              }
            />
            <TextField
              source='observation'
              label='resources.representatives.fields.observation'
              emptyText='-'
            />
          </Datagrid>
        </ArrayField>
        <FormTitle
          title={translate('resources.information-bank.fields.name')}
          className={classes.width100}
        />
        <ArrayField
          fieldKey='id'
          addLabel={false}
          label=''
          source='bankInformation'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Información bancaria' />}>
            <TextField
              source='productType'
              emptyText='-'
              label={translate('resources.information-bank.fields.productType')}
            />
            <TextField
              source='bank.name'
              emptyText='-'
              label={translate('resources.information-bank.fields.bank')}
            />
            <TextField
              source='number'
              emptyText='-'
              label={translate('resources.information-bank.fields.number')}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    );
  }
};

export default MoralPersonShow;
