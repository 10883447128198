import React from 'react';
import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';

import {
  addressTypes,
  banks,
  departments,
  positions,
  substates,
  promotions,
} from '../../../Views';
import ListItemLink from '../ListItemLink';

const Others = ({ classes, iconColor }) => {
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant='h4' component='h2'>
          Otros
        </Typography>
        <Divider />
        <List className={classes.list} aria-labelledby='conjuration subtitle'>
          <ListItemLink
            icon={<banks.icon />}
            primary='Bancos'
            to='/banks'
            color={iconColor}
          />
          <ListItemLink
            icon={<departments.icon />}
            primary='Departamentos'
            to='/departments'
            color={iconColor}
          />
          <ListItemLink
            icon={<positions.icon />}
            primary='Posiciones'
            to='/positions'
            color={iconColor}
          />
          <ListItemLink
            icon={<addressTypes.icon />}
            primary='Tipos de Direcciones'
            to='/address-types'
            color={iconColor}
          />
          <ListItemLink
            icon={<substates.icon />}
            primary='Subestados'
            to='/substates'
            color={iconColor}
          />
          <ListItemLink
            icon={<promotions.icon />}
            primary='Promociones'
            to='/promotions'
            color={iconColor}
          />
        </List>
      </Paper>
    </Grid>
  );
};

export default Others;
