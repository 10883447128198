import React from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ChipField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';

import ListActions from '../../Components/ListActions';
import CustomList from '../../Components/CustomList';

import { Company, StateType } from '../../Utils/enums';

const StateRuleFilter = (props) => {
  const company = JSON.parse(localStorage.getItem('user') || '{}').company?.id;
  return (
    <Filter {...props} variant='outlined'>
      <TextInput source='name' resettable alwaysOn />
      <ReferenceInput
        reference='states'
        source='actualState'
        resettable
        filter={{
          stateTypeId:
            company === Company.IbanOnlineMx
              ? StateType.LoanApplicationIO
              : StateType.LoanApplicationKap,
        }}
      >
        <SelectInput source='name' />
      </ReferenceInput>
      <ReferenceInput
        reference='states'
        source='futureState'
        resettable
        sort={{ field: 'id', order: 'ASC' }}
        filter={{
          stateTypeId:
            company === Company.IbanOnlineMx
              ? StateType.LoanApplicationIO
              : StateType.LoanApplicationKap,
        }}
      >
        <SelectInput source='name' />
      </ReferenceInput>
    </Filter>
  );
};

export const StateRuleList = ({ permissions, ...props }) => {
  return (
    <CustomList
      filters={<StateRuleFilter />}
      actions={
        <ListActions entity='state-rule' permissions={permissions} {...props} />
      }
      {...props}
    >
      <Datagrid rowClick='show'>
        <TextField source='id' />
        <TextField source='name' />
        <ReferenceField source='actualState' reference='states' link={false}>
          <ChipField source='name' size='small' />
        </ReferenceField>
        <ReferenceField source='futureState' reference='states' link={false}>
          <ChipField source='name' size='small' />
        </ReferenceField>
      </Datagrid>
    </CustomList>
  );
};

export default StateRuleList;
