import React from 'react';
import {
  Edit,
  ListButton,
  TopToolbar,
  useNotify,
  useRefresh,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import PersonForm from './PersonForm';
import { errors, thereWasProblem } from '../../Utils/messages';

const PersonActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const PersonEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    refresh();

    const code = error.body.data.errorCode;
    notify(
      `${thereWasProblem}: ${
        errors[code] || 'Hubo un error con su solicitud, inténtelo mas tarde...'
      }`,
      'warning'
    );
  };

  return (
    <Edit
      actions={<PersonActions {...props} />}
      onFailure={onFailure}
      {...props}
    >
      <PersonForm />
    </Edit>
  );
};

export default PersonEdit;
