import React, { useState } from 'react';
import {
  FormWithRedirect,
  ReferenceInput,
  required,
  email,
  SaveButton,
  TextInput,
  useTranslate,
  AutocompleteInput,
  PasswordInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { FormWithRedirectOwnProps } from 'ra-core/esm/form/FormWithRedirect';
import { FormProps } from 'react-final-form';
import Typography from '@material-ui/core/Typography';

import Contacts from '../../Components/Contacts';
import FormTitle from '../../Components/FormTitle';
import { AuthRole } from '../../Utils/enums';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
    },
  })
);

const isRequired = [required()];

export const EmployeeForm = (
  props: JSX.IntrinsicAttributes &
    FormWithRedirectOwnProps &
    Omit<
      FormProps<Record<string, any>, Partial<Record<string, any>>>,
      'onSubmit'
    >
) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [values, setValues] = useState<any>();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { role, company } = user;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        if (!values) setValues(formProps.record);
        return (
          <>
            <Grid container spacing={6} classes={{ root: classes.root }}>
              <Grid item xs={6}>
                <Typography variant='h4' gutterBottom>
                  Empleado
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ReferenceInput
                      source={
                        formProps.record.id ? 'department.id' : 'department'
                      }
                      resource='employees'
                      reference='departments'
                      fullWidth
                      validate={isRequired}
                      onChange={() => {
                        setValues(formProps.form.getState().values);
                        formProps.form.change('position', '');
                      }}
                      variant='outlined'
                      filter={
                        company?.id
                          ? {
                              company: company?.id,
                            }
                          : {
                              company: company,
                            }
                      }
                    >
                      <AutocompleteInput optionText='name' />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput
                      source='position'
                      resource='employees'
                      reference='positions'
                      disabled={!values?.department}
                      fullWidth
                      validate={isRequired}
                      variant='outlined'
                      filter={
                        values?.department?.id
                          ? {
                              department: values?.department?.id,
                            }
                          : {
                              department: values?.department,
                            }
                      }
                    >
                      <AutocompleteInput optionText='name' />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      source='code'
                      resource='employees'
                      validate={isRequired}
                      fullWidth
                      disabled={
                        props.record.id &&
                        role.id !== AuthRole.Admin &&
                        role.id !== AuthRole.CommandCenter &&
                        role.id !== AuthRole.HumanResource
                      }
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!props.record.id ? (
                      <ReferenceInput
                        source='person'
                        resource='employees'
                        reference='employees/available-people'
                        fullWidth
                        validate={isRequired}
                        variant='outlined'
                      >
                        <AutocompleteInput
                          helperText='Las personas desplegadas en este componente deben estar marcadas como empleado en el listado de clientes personales y aún no están relacionadas a un empleado. Es posible que salga vacío en caso de no existir personas con esta situación.'
                          optionText={(record) =>
                            record &&
                            `${record.documentNumber} - ${record.firstName} ${record.fatherLastName}`
                          }
                        />
                      </ReferenceInput>
                    ) : (
                      <ReferenceInput
                        source='person'
                        resource='employees'
                        reference='people'
                        fullWidth
                        disabled
                        variant='outlined'
                      >
                        <AutocompleteInput
                          optionText={(record) =>
                            record &&
                            `${record.documentNumber} - ${record.firstName} ${record.fatherLastName}`
                          }
                        />
                      </ReferenceInput>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {!props.record.id && (
                <Grid item xs={6}>
                  <Typography variant='h4' gutterBottom>
                    Usuario
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextInput
                        source='email'
                        resource='users'
                        validate={[required(), email()]}
                        fullWidth
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PasswordInput
                        source='password'
                        resource='users'
                        validate={isRequired}
                        fullWidth
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormTitle
                  title={translate('resources.contacts.fields.name')}
                  className={classes.width100}
                />
                <Contacts record={formProps.record} isValidated />
              </Grid>
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

export default EmployeeForm;
