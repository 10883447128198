import React, { useState } from 'react';
import {
  required,
  FormWithRedirect,
  NumberInput,
  BooleanInput,
  Button,
  minValue,
  maxValue,
  SelectInput,
  TextInput,
  useNotify,
  DateInput,
} from 'react-admin';

import { DivideSquare, DownloadCloud } from 'react-feather';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { ApplicationType, monthsP2P, ratings } from '../Utils/enums';
import type { Theme } from '../Theme';
import Cookies from '../Utils/cookies';
import {
  formatInputNumber,
  formatNumber,
  parseInputNumber,
} from '../Utils/functions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    tableRoot: {
      height: 'auto',
      flex: 1,
      border: `1px solid ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)'
      }`,
      display: 'flex',
      outline: 'none',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 400,
      lineHeight: 1.43,
      borderRadius: '4px',
      flexDirection: 'column',
      letterSpacing: '0.01071em',
    },
    tableMain: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
    },
    tableHeadersContainer: {
      display: 'flex',
      width: '100%',
    },
    tableHeaders: {
      width: '100%',
      display: 'flex',
      borderSpacing: 0,
      borderBottom: `1px solid ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)'
      }`,
      '& > th:last-child': {
        borderRight: 'none',
      },
    },
    tableHeader: {
      padding: '5px 10px',
      textAlign: 'right',
      borderRight: `1px solid ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)'
      }`,
      boxSizing: 'border-box',
    },
    tableRowsContainer: {
      display: 'flex',
      flexDirection: 'column',
      '& > tr:last-child': {
        borderBottom: 'none',
      },
    },
    tableRow: {
      width: '100%',
      display: 'flex',
      borderSpacing: 0,
      borderBottom: `1px solid ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.23)'
          : 'rgba(255, 255, 255, 0.23)'
      }`,
      '&:hover': { background: 'rgba(81, 81, 81, 0.20)' },
    },
    tableRowData: {
      padding: '0 10px',
      textAlign: 'right',
      minHeight: '56px',
      maxHeight: '56px',
      lineHeight: '56px',
      boxSizing: 'border-box',
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      '& > p': {
        display: 'none',
      },
    },
    width50: {
      width: '50%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
    value: {
      fontSize: '1rem',
      fontFamily: theme.typography.fontFamily,
      margin: '6px 0px',
    },
    label: {
      fontSize: '0.75rem',
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.fontFamily,
    },
  })
);

const RenderValues = ({ label, value, ...rest }) => {
  const classes = useStyles();
  const { helperText, isAmount = false } = rest;

  return (
    <div style={{ width: '100%' }}>
      <label className={classes.label}>{label}</label>
      <p className={classes.value}>
        {value !== undefined && value !== null
          ? isAmount
            ? formatNumber(value)
            : value
          : '-'}
      </p>
      {helperText ? <em className={classes.label}>{helperText}</em> : null}
    </div>
  );
};

const LoanCalculator = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const token = Cookies.getCookie('token');
  const [ivaOn, setIVA] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values, type = 'loan') => {
    setLoading(true);
    const {
      id,
      amount,
      months,
      interestRate,
      promoInterest,
      requestedExtensionTerm,
      withIVA,
      rating,
      expensesIVA,
      interestIVA,
      grantDate,
    } = values;

    fetch(
      `${process.env.REACT_APP_API_URL}/amortization-table${
        type === 'print' ? '/generate-pdf' : '/sicanet/simulate'
      }?loanApplicationId=${id}&amount=${amount}&months=${months}&interestRate=${interestRate}&grantDate=${grantDate}&extensionMonths=${
        requestedExtensionTerm || 0
      }&withIVA=${withIVA || false}${rating ? `&rating=${rating}` : ''}${
        promoInterest ? `&promoInterest=${promoInterest}` : ''
      }${expensesIVA ? `&withExpensesIVA=true` : ''}${
        interestIVA ? `&withInterestIVA=true` : ''
      }`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then(async (res) => {
      const parsedData = await res.json();
      if (parsedData.statusCode === 403) {
        window.location.href = `/#/403`;
        return;
      }
      if (parsedData.statusCode === 400) {
        setLoading(false);

        return notify(
          parsedData.data?.error?.msg || 'Hubo un error.',
          'warning'
        );
      }

      if (type === 'print') {
        if (parsedData.statusCode === 400) {
          setLoading(false);
          return notify('Esta solicitud necesita un país.', 'warning');
        }

        const windowPrint = window.open('', '', 'height=700,width=700');
        windowPrint!.document.write(parsedData.html);
        windowPrint!.document.close();
        setLoading(false);

        setTimeout(() => {
          windowPrint!.print();
        }, 500);

        return;
      }

      setLoading(false);
      setRows(parsedData);
    });
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        const colWidth = '13.57%';

        return (
          <Grid container spacing={1} classes={{ root: classes.root }}>
            <Grid item xs={12}>
              <Typography variant='h4' gutterBottom>
                Información general
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TextInput
                source='amount'
                resource='loan-applications'
                validate={[required(), minValue(0)]}
                format={(v) => formatInputNumber(v)}
                parse={(v) => parseInputNumber(v)}
                fullWidth
                label='resources.loan-applications.fields.requestedAmount'
                className={classes.width100}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={3}>
              {props?.record?.applicationType?.id === ApplicationType.P2P ? (
                <SelectInput
                  fullWidth
                  source='months'
                  resource='loan-applications'
                  variant='outlined'
                  choices={monthsP2P}
                  className={classes.width100}
                  validate={[
                    required(),
                    maxValue(120, 'La cantidad máxima es 120 meses'),
                  ]}
                />
              ) : (
                <NumberInput
                  source='months'
                  resource='loan-applications'
                  validate={[required(), minValue(0), maxValue(120)]}
                  min={0}
                  max={120}
                  fullWidth
                  label='resources.loan-applications.fields.requestedTerm'
                  className={classes.width100}
                  variant='outlined'
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <NumberInput
                source='interestRate'
                resource='loan-applications'
                validate={[required(), minValue(0), maxValue(5)]}
                fullWidth
                min={0}
                max={5}
                step={0.1}
                label='resources.loan-applications.fields.requestedRate'
                className={classes.width100}
                variant='outlined'
              />
            </Grid>
            {props?.record?.applicationType?.id ===
              ApplicationType.Promotion && (
              <Grid item xs={3}>
                <NumberInput
                  source='promoInterest'
                  resource='loan-applications'
                  validate={[required(), minValue(0), maxValue(5)]}
                  fullWidth
                  min={0}
                  max={5}
                  step={0.1}
                  label='resources.loan-applications.fields.promoInterest'
                  className={classes.width100}
                  variant='outlined'
                />
              </Grid>
            )}
            {props?.record?.applicationType?.id === ApplicationType.P2P && (
              <Grid item xs={3}>
                <SelectInput
                  choices={ratings}
                  source='rating'
                  fullWidth
                  variant='outlined'
                  validate={required()}
                  label='resources.rating.fields.rating'
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <NumberInput
                source='requestedExtensionTerm'
                resource='loan-applications'
                validate={[minValue(0), maxValue(12)]}
                min={0}
                max={12}
                fullWidth
                className={classes.width100}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={3}>
              <DateInput
                fullWidth
                source='grantDate'
                variant='outlined'
                validate={required()}
                label='resources.loan-applications.fields.grantDate'
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={3}>
                <BooleanInput
                  source='withIVA'
                  defaultValue={false}
                  resource='loan-applications'
                  className={classes.width100}
                  onChange={(value) => setIVA(value)}
                />
                {ivaOn && (
                  <Grid
                    item
                    xs={6}
                    style={{ maxWidth: '100%', display: 'flex' }}
                  >
                    <BooleanInput
                      source='expensesIVA'
                      defaultValue={true}
                      resource='loan-applications'
                      className={classes.width50}
                    />
                    <BooleanInput
                      source='interestIVA'
                      defaultValue={true}
                      resource='loan-applications'
                      className={classes.width50}
                    />
                  </Grid>
                )}
              </Grid>
              <Button
                label='Calcular'
                variant='contained'
                style={{ marginRight: '15px' }}
                startIcon={<DivideSquare />}
                disabled={formProps.invalid}
                onClick={() => handleSubmit(formProps.form.getState().values)}
              />
              {rows?.dues?.length && (
                <Button
                  label='Descargar'
                  variant='contained'
                  startIcon={<DownloadCloud />}
                  disabled={formProps.invalid}
                  onClick={() =>
                    handleSubmit(formProps.form.getState().values, 'print')
                  }
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <RenderValues
                label='Cuota sin prórroga'
                helperText='No afecta el valor de contrato.'
                value={rows.quota}
              />
            </Grid>
            <Grid item xs={3}>
              <RenderValues
                label='Cuota con prórroga'
                helperText='No afecta el valor de contrato.'
                value={rows.quotaWithExtension}
              />
            </Grid>
            <Grid item xs={3}>
              <RenderValues
                label='Comisión de apertura'
                value={props?.record?.openingCommission}
                isAmount
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.tableRoot}>
                <table className={classes.tableMain}>
                  <div
                    className={classes.tableHeadersContainer}
                    style={{
                      minHeight: '56px',
                    }}
                  >
                    <tr className={classes.tableHeaders}>
                      <th
                        className={classes.tableHeader}
                        style={{ width: '5%' }}
                      >
                        No.
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        Fecha
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        Saldo insoluto inicial
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        Intereses ordinarios
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        IVA de intereses
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        Capital
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        Gastos
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        IVA de gastos
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        Pago total
                      </th>
                      <th
                        className={classes.tableHeader}
                        style={{ width: colWidth }}
                      >
                        Saldo insoluto final
                      </th>
                    </tr>
                  </div>
                  <div
                    className={classes.tableRowsContainer}
                    style={{
                      minHeight: `${
                        rows?.dues ? rows?.dues.length * 56 + 1 : 150
                      }px`,
                    }}
                  >
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          paddingTop: '40px',
                          justifyContent: 'center',
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      rows?.dues?.map((row, index) => (
                        <tr
                          className={classes.tableRow}
                          style={{ top: `${56 * (index + 1)}px` }}
                        >
                          <td
                            className={classes.tableRowData}
                            style={{ width: '5%' }}
                          >
                            {row.no}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{ width: colWidth }}
                          >
                            {row.paymentDate}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{ width: colWidth }}
                          >
                            {row.amount}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{ width: colWidth }}
                          >
                            {row.interest}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{ width: colWidth }}
                          >
                            {row.ivaOfInterest}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{ width: colWidth }}
                          >
                            {row.capital}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{
                              width: colWidth,
                            }}
                          >
                            {row.quotaExpenses}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{ width: colWidth }}
                          >
                            {row.ivaOfQuotaExpenses}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{
                              width: colWidth,
                            }}
                          >
                            {row.quotaWithIva}
                          </td>
                          <td
                            className={classes.tableRowData}
                            style={{
                              width: colWidth,
                            }}
                          >
                            {row.capitalRemaining}
                          </td>
                        </tr>
                      ))
                    )}
                  </div>
                </table>
              </div>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default LoanCalculator;
