import React from 'react';
import type { FC } from 'react';
import { useRedirect } from 'ra-core';
import {
  DataGrid,
  esES,
  GridValueFormatterParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { makeStyles } from '@material-ui/styles';

import { formatNumber } from '../../../Utils/functions';
import { Theme } from '../../../theme';

interface Columns {
  field: string;
  headerName: string;
  width: number;
  editable?: boolean;
  type?: string;
}

interface TableProps {
  className?: string;
  rows?: any;
  columns?: Columns[];
  setEmployee?: (employee: string) => void;
}

const columnsItems = [
  {
    field: 'applicationType',
    headerName: 'Tipo de solicitud',
    editable: false,
    width: 250,
  },
  {
    field: 'state',
    headerName: 'Estado de la solicitud',
    editable: false,
    width: 250,
  },
  {
    field: 'quantity',
    headerName: 'Cantidad',
    type: 'number',
    editable: false,
    width: 150,
  },
  {
    field: 'total',
    headerName: 'Monto total',
    type: 'number',
    editable: false,
    width: 250,
    valueFormatter: (params: GridValueFormatterParams) => {
      const valueFormatted = formatNumber(params.value);
      return `MXN ${valueFormatted}`;
    },
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

const CustomGridToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarFilterButton />
  </GridToolbarContainer>
);

const Table: FC<TableProps> = ({
  className,
  rows,
  columns,
  setEmployee,
  ...rest
}) => {
  const classes = useStyles();
  const redirect = useRedirect();

  for (let i = 0; i < rows.length; i++) {
    rows[i]['id'] = i + 1;
  }

  return (
    <div style={{ height: 400, width: '100%' }} className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns ? columns : columnsItems}
        density='compact'
        pageSize={10}
        onRowClick={(e) =>
          typeof setEmployee !== 'undefined'
            ? setEmployee(e.row.employeeCode)
            : null
        }
        onRowDoubleClick={(e) =>
          redirect(
            `/loan-applications?displayedFilters=%7B"agent"%3Atrue%7D&filter=%7B"agent"%3A${e.row.employeeId}%7D&order=DESC&page=1&perPage=15&sort=id`
          )
        }
        components={{
          Toolbar: CustomGridToolbar,
        }}
        localeText={esES.props.MuiDataGrid.localeText}
        disableSelectionOnClick={
          typeof setEmployee === 'undefined' ? true : false
        }
      />
    </div>
  );
};

export default Table;
