import React, { useState } from 'react';
import {
  DateInput,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  useTranslate,
  SelectInput,
  maxLength,
  TextInput,
  BooleanInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { AuthRole, Company, DocumentType, sex, yesNo } from '../../Utils/enums';
import Contacts from '../../Components/Contacts';
import Addresses from '../../Components/Addresses';
import FormTitle from '../../Components/FormTitle';
import Representatives from '../../Components/Representatives';
import InformationBank from '../../Components/InformationBank';
import FormatNumberInput from '../../Components/FormatNumberInput';
import OtherDocuments from '../../Components/OtherDocuments';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
  })
);

export const PersonForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [isClient, setIsClient] = useState(true);
  const [showDictum, setShowDictum] = useState(props?.record?.blacklist);
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const role = user.role?.id;
  const today = new Date().toISOString().split('T')[0].split('-');

  const record = (props as any).record || {};
  const isNew = !record?.id;
  const company =
    (isNew ? user.company?.id : record?.company) || Company.IbanOnlineMx;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <>
          <Grid container spacing={2} classes={{ root: classes.root }}>
            <Grid item xs={12}>
              <Typography variant='h4' gutterBottom>
                General
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <TextInput
                    source='firstName'
                    resource='people'
                    validate={[required(), maxLength(40)]}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    source='secondName'
                    resource='people'
                    validate={[maxLength(40)]}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    source='fatherLastName'
                    resource='people'
                    validate={[required(), maxLength(40)]}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    source='motherLastName'
                    resource='people'
                    validate={[maxLength(40)]}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='documentType.id'
                    resource='people'
                    reference='document-types'
                    fullWidth
                    disabled
                    variant='outlined'
                    validate={[required()]}
                    defaultValue={DocumentType.CURP}
                  >
                    <SelectInput optionText='name' />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    source='documentNumber'
                    resource='people'
                    validate={[required(), maxLength(40)]}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <DateInput
                    source='birthday'
                    resource='people'
                    inputProps={{
                      max: isClient
                        ? [Number(today[0]) - 21, today[1], today[2]].join('-')
                        : today.join('-'),
                    }}
                    validate={[required()]}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='countryOfBirth'
                    resource='people'
                    validate={[required()]}
                    reference='countries'
                    fullWidth
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='nationality.id'
                    resource='people'
                    validate={[required()]}
                    reference='countries'
                    fullWidth
                    variant='outlined'
                  >
                    <SelectInput optionText='nationality' />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='maritalStatus.id'
                    resource='people'
                    reference='marital-statuses'
                    fullWidth
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <SelectInput
                    source='sex'
                    resource='people'
                    choices={sex}
                    fullWidth
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='academicLevel.id'
                    resource='people'
                    reference='academic-levels'
                    fullWidth
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='profession.id'
                    resource='people'
                    reference='professions'
                    fullWidth
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='occupation.id'
                    resource='people'
                    reference='occupations'
                    fullWidth
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                  <ReferenceInput
                    source='economicActivity.id'
                    resource='people'
                    reference='economic-activities'
                    fullWidth
                    variant='outlined'
                  >
                    <SelectInput />
                  </ReferenceInput>
                </Grid>
                <Grid container alignItems='center' xs={3}>
                  <Grid xs={6}>
                    <BooleanInput
                      style={{ marginLeft: '5%' }}
                      source='isEmployee'
                      resource='people'
                      defaultValue={false}
                    />
                  </Grid>
                  <Grid item xs={5} style={{ marginLeft: '10px' }}>
                    <BooleanInput
                      style={{ marginLeft: '5%' }}
                      source='isClient'
                      onChange={(value) => setIsClient(value)}
                      resource='people'
                      defaultValue={true}
                    />
                  </Grid>
                </Grid>
                {(role === AuthRole.Risk || role === AuthRole.Admin) && (
                  <Grid item xs={3}>
                    <FormatNumberInput
                      source='billingIncome'
                      resource='people'
                    />
                  </Grid>
                )}
                {(role === AuthRole.Risk || role === AuthRole.Admin) && (
                  <Grid item xs={3}>
                    <FormatNumberInput
                      source='statementIncome'
                      resource='people'
                    />
                  </Grid>
                )}
                {(role === AuthRole.Risk || role === AuthRole.Admin) && (
                  <Grid item xs={3}>
                    <FormatNumberInput
                      source='financialInformationIncome'
                      resource='people'
                    />
                  </Grid>
                )}
                {company === Company.KapitalizerOnlineMX && (
                  <Grid item xs={3}>
                    <SelectInput
                      source='hasGuarantee'
                      resource='people'
                      choices={yesNo}
                      fullWidth
                      variant='outlined'
                      defaultValue={true}
                    />
                  </Grid>
                )}
                {company === Company.KapitalizerOnlineMX && (
                  <Grid item xs={3}>
                    <SelectInput
                      source='isGuaranteeOwner'
                      resource='people'
                      choices={yesNo}
                      fullWidth
                      variant='outlined'
                      defaultValue={true}
                    />
                  </Grid>
                )}
                {[AuthRole.Risk, AuthRole.Admin].includes(role) &&
                  formProps.record?.id && (
                    <Grid item xs={3}>
                      <BooleanInput
                        source='blacklist'
                        resource='people'
                        defaultValue={false}
                        onChange={(val) => setShowDictum(val)}
                      />
                    </Grid>
                  )}
                {showDictum &&
                [AuthRole.Risk, AuthRole.Admin].includes(role) ? (
                  <Grid item xs={3}>
                    <ReferenceInput
                      fullWidth
                      source='blacklistDictum'
                      variant='outlined'
                      reference='blacklist-dictums'
                      resource='blacklists'
                      validate={required()}
                    >
                      <SelectInput optionText='code' />
                    </ReferenceInput>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormTitle title='Otros Documentos' />
              <OtherDocuments record={formProps.record} {...props} />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title='Contactos' />
              <Contacts record={formProps.record} isValidate={true} />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title='Direcciones' />
              <Addresses record={formProps.record} isValidate={true} />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title='Representantes' />
              <Representatives {...formProps} />
            </Grid>
            <Grid item xs={12}>
              <FormTitle title={translate('Información Bancaria')} />
              <InformationBank record={formProps.record} />
            </Grid>
          </Grid>
          <Toolbar>
            <Box display='flex' justifyContent='space-between' width='100%'>
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                redirect='list'
              />
            </Box>
          </Toolbar>
        </>
      )}
    />
  );
};

export default PersonForm;
