import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';
import { useTheme } from '@material-ui/core';
import type { Theme } from '../../../../../Theme';
import { calculatePercentages } from '../../../../../Utils/functions';
interface ChartProps {
  className?: string;
  data: any;
}

const Chart: FC<ChartProps> = ({ data: dataProp, ...rest }) => {
  const theme: Theme = useTheme();
  const percentages = calculatePercentages(dataProp.datasets[0].data);
  const labelsWithPercentages = [];
  for (let i = 0; i < dataProp.labels.length; i++) {
    labelsWithPercentages.push(`${dataProp.labels[i]} - ${percentages[i]}`);
  }

  const data = {
    datasets: dataProp.datasets.map((dataset) => ({
      ...dataset,
      borderWidth: 2,
      borderColor: theme.palette.background.default,
      hoverBorderColor: theme.palette.background.default,
    })),
    labels: labelsWithPercentages,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    legend: {
      display: false,
    },
    layout: {
      padding: 0,
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      caretSize: 10,
      yPadding: 20,
      xPadding: 20,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.background.dark,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
    },
  };

  return <Pie data={data} options={options} />;
};

Chart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default Chart;
