import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Check, X, Link as LinkIcon, Trash } from 'react-feather';
import { Entity, RichUtils } from 'draft-js';
import { draftTextInputStyles } from './DraftTextInputStyles';

const DraftTextInputLinkButton = ({ editorState, onChange }: any) => {
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');
  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement && inputElement.current)
      (inputElement.current as any)?.focus();
  }, [showURLInput]);

  // SHOW / HIDE
  const getCurrentLink = (selection: any) => {
    const currentBlockContainsLink =
      RichUtils.currentBlockContainsLink(editorState);
    if (!currentBlockContainsLink) return '';
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      return linkInstance.getData().url;
    }
    return '';
  };
  const showForm = (event: any) => {
    event.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setUrlValue(getCurrentLink(selection));
      setShowURLInput(true);
    }
  };
  const closeForm = (event?: any) => {
    event?.preventDefault();
    setShowURLInput(false);
    setUrlValue('');
  };

  // URL
  const handleChange = (event: any) => setUrlValue(event.target.value);

  // CRATE LINK
  const handleSubmit = (event: any) => {
    event.preventDefault();
    confirmLink();
  };

  const confirmLink = () => {
    setLink(urlValue);
    closeForm();
  };

  const setLink = (url: string) => {
    const selection = editorState.getSelection();
    if (url.length > 0) {
      const entityKey = Entity.create('LINK', 'MUTABLE', { url: url });
      toggleLink(selection, entityKey);
    }
  };

  const removeLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      toggleLink(selection, null);
    }
  };

  const toggleLink = (selection, entityKey) => {
    onChange(
      RichUtils.toggleLink(editorState, selection, entityKey),
      selection
    );
  };

  // INPUT
  const urlInput = (
    <form
      id='urlForm'
      style={draftTextInputStyles.draftLinkForm}
      onSubmit={handleSubmit}
    >
      <input
        type='text'
        id='outlined-basic'
        placeholder='Type or paste link here'
        ref={inputElement}
        className='linkText'
        onChange={handleChange}
        value={urlValue}
        style={styles.inputUrl}
      />

      <Button type='submit' form='urlForm'>
        <Check className='icon-checkmark3'></Check>
      </Button>
      <Button className='draft__link__close' onClick={closeForm}>
        <X className='icon-close'></X>
      </Button>
    </form>
  );

  const renderUnsetButton = () => {
    const currentBlockContainsLink =
      RichUtils.currentBlockContainsLink(editorState);
    return (
      currentBlockContainsLink && (
        <button
          className='draft__link__close'
          style={draftTextInputStyles.toolbarButton}
          onClick={removeLink}
        >
          <Trash size={15}></Trash>
        </button>
      )
    );
  };

  return (
    <div style={draftTextInputStyles.draftLinkWrap}>
      <button
        className='draft__toggle draft__link__toggle'
        style={draftTextInputStyles.toolbarButton}
        onClick={showForm}
      >
        <LinkIcon className='icon icon-link' size={15}></LinkIcon>
      </button>
      {renderUnsetButton()}

      {showURLInput ? urlInput : null}
    </div>
  );
};

const styles = {
  inputUrl: {
    font: 'inherit',
    border: '0',
    background: 'none',
    color: 'currentColor',
    width: '100%',
  },
};

export default DraftTextInputLinkButton;
