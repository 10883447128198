import React, { useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  maxLength,
  DateInput
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Toolbar } from '@material-ui/core';
import Addresses from '../../Components/Addresses';
import Contacts from '../../Components/Contacts';
import Typography from '@material-ui/core/Typography';
import FormTitle from '../../Components/FormTitle';
import { AuthRole, DocumentType } from '../../Utils/enums';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
  })
);

const isRequired = [required()];

const FranchiseForm = (props: any) => {
  const classes = useStyles();
  const [showDictum, setShowDictum] = useState(props?.record?.blacklist);
  const role = JSON.parse(localStorage.getItem('user') || '{}').role.id;

  return (
    <FormWithRedirect
      variant='outlined'
      className={classes.root}
      {...props}
      render={(formProps) => {
        return (
          <form>
            <Grid item xs={12} classes={{ root: classes.root }}>
              <Typography variant='h3' gutterBottom className={classes.paper}>
                General
              </Typography>
              <Grid container spacing={1} classes={{ root: classes.root }}>
                <Grid item xs={4}>
                  <TextInput
                    source='code'
                    helperText='En caso de no llenar se generará automáticamente'
                    fullWidth
                    variant='outlined'
                    resource='franchises'
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source='name'
                    validate={isRequired}
                    fullWidth
                    variant='outlined'
                    resource='franchises'
                  />
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source='manager'
                    reference='franchises/available-people'
                    validate={isRequired}
                    fullWidth
                    variant='outlined'
                    resource='franchises'
                    record={formProps.record}
                  >
                    <AutocompleteInput
                      optionText={(record) =>
                        record &&
                        `${record.firstName} ${record.fatherLastName ?? ''} – ${
                          record.documentNumber
                        }`
                      }
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source='masterBroker'
                    reference='franchises'
                    fullWidth
                    variant='outlined'
                    resource='franchises'
                    sort={{ field: 'name', order: 'ASC' }}
                    disabled={props.record?.id}
                    filter={{ masterBroker: true }}
                  >
                    <AutocompleteInput optionText='name' />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    <ReferenceInput
                      source='documentType.id'
                      resource='franchises'
                      reference='document-types'
                      fullWidth
                      disabled
                      variant='outlined'
                      validate={[required()]}
                      defaultValue={DocumentType.RFC}
                    >
                      <SelectInput optionText='name' />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput
                      source='documentNumber'
                      resource='franchises'
                      validate={[required(), maxLength(40)]}
                      fullWidth
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateInput
                      source='constitutionDate'
                      resource='franchises'
                      fullWidth
                      variant='outlined'
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateInput
                      source='issuanceRegistrationDate'
                      resource='franchises'
                      fullWidth
                      variant='outlined'
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DateInput
                      source='registrationExpirationDate'
                      resource='franchises'
                      fullWidth
                      variant='outlined'
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                {[AuthRole.Risk, AuthRole.Admin].includes(role) &&
                  formProps.record?.id && (
                    <Grid item xs={3}>
                      <BooleanInput
                        source='blacklist'
                        resource='franchises'
                        defaultValue={false}
                        onChange={(val) => setShowDictum(val)}
                      />
                    </Grid>
                  )}
                {showDictum &&
                [AuthRole.Risk, AuthRole.Admin].includes(role) ? (
                  <Grid item xs={3}>
                    <ReferenceInput
                      fullWidth
                      source='blacklistDictum'
                      variant='outlined'
                      reference='blacklist-dictums'
                      resource='blacklists'
                      validate={required()}
                    >
                      <SelectInput optionText='code' />
                    </ReferenceInput>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <FormTitle title='Contactos' />
                  <Contacts record={formProps.record} />
                </Grid>
                <Grid item xs={12}>
                  <FormTitle title='Direcciones' />
                  <Addresses record={formProps.record} />
                </Grid>
              </Grid>
              <Toolbar>
                <Box display='flex' justifyContent='space-between' width='100%'>
                  <SaveButton
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                    redirect='list'
                  />
                </Box>
              </Toolbar>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default FranchiseForm;
