import React from 'react';
import {
  FormWithRedirect,
  required,
  SaveButton,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  TextInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { Box, Toolbar } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import PasswordResetButton from './PasswordResetButton';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
    },
  })
);

interface UserInterface {
  props?: object;
}

const isRequired = [required()];

export const UserForm: React.FC<UserInterface> = (props) => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        return (
          <>
            <Grid container spacing={4} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  Usuario
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  source='email'
                  resource='users'
                  disabled
                  fullWidth
                  variant='outlined'
                />
                <ReferenceInput
                  source='userType.id'
                  resource='users'
                  reference='user-types'
                  link={false}
                >
                  <SelectInput
                    source='name'
                    resource='users'
                    fullWidth
                    variant='outlined'
                    validate={isRequired}
                  />
                </ReferenceInput>
                <BooleanInput source='blocked' resource='users' fullWidth />
              </Grid>
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
                <PasswordResetButton record={formProps.record} />
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

export default UserForm;
