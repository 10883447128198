import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  CardContent,
} from '@material-ui/core';
import type { Theme } from '../../../../../Theme';
import Chart from './Chart';

interface PieGraphicProps {
  className?: string;
  record: any;
  noLabel?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    textAlign: 'center',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const PieGraphic: FC<PieGraphicProps> = ({
  className,
  record,
  noLabel,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        {!noLabel ? <CardHeader title='Solicitudes por estado' /> : null}
        {!noLabel ? <Divider /> : null}
        <Box position='relative' height={415}>
          <Chart data={record} />
        </Box>
      </CardContent>
    </Card>
  );
};

PieGraphic.propTypes = {
  className: PropTypes.string,
};

export default PieGraphic;
