import { Create, ListButton, TopToolbar } from 'react-admin';

import ChevronLeft from '@material-ui/icons/ChevronLeft';

import OccupationForm from './OccupationForm';

const OccupationActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const OccupationCreate = (props: any) => {
  return (
    <Create actions={<OccupationActions {...props} />} {...props}>
      <OccupationForm />
    </Create>
  );
};

export default OccupationCreate;
