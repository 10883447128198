import React from 'react';
import type { FC } from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import type { Theme } from '../../../Theme';
import Page from '../../../Components/Page';
import InformationCard from '../Components/InformationCard';
import BarGraphic from '../Components/Graphics/Bar';
import CardsSegment from './Cards';
import SegmentGraphic from './Segments';
import Header from '../Components/Header';
import ShowHeader from '../Components/ShowHeader';
import Table from '../Components/Table';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
}));

const DashboardMasterBroker: FC<any> = ({ record }: any) => {
  const classes = useStyles();

  if (record.length === 0 || record?.statusCode === 500) return <ShowHeader />;

  const earnings = {
    datasets: [
      {
        backgroundColor: [
          '#3d72eb',
          '#b658f5',
          '#4b9e86',
          '#FF5733',
          '#900C3F',
          '#2ECC71',
          '#1F618D',
          '#D4AC0D',
          '#979A9A',
          '#487aac',
          '#494949',
          '#83791f',
          '#168158',
          '#02101f',
        ],
        data: [],
      },
    ],
    labels: [],
  };

  const stats = [];
  const labels = [];

  if (record) {
    for (let res of record.dashboard) {
      earnings.datasets[0].data.push(res?.quantity);
      if (labels.includes(res.applicationType)) {
        const index = labels.indexOf(res.applicationType);
        stats[index] += parseInt(res.quantity);
      } else {
        labels.push(res.applicationType);
        stats.push(parseInt(res.quantity));
      }
    }
  }

  return (
    <Page className={classes.root} title='IDEXI'>
      <Container maxWidth='lg'>
        <Header altText='franquicias' />
        <Grid container spacing={3}>
          {record?.dashboardTotal.map((data) => {
            return (
              <>
                <Grid item lg={4} sm={6} xs={12}>
                  <InformationCard
                    tittle='Franquicias'
                    amount={data?.totalFranchises}
                    icon={<HomeWorkIcon />}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <InformationCard
                    tittle='Solicitudes'
                    amount={data?.totalApplications}
                    icon={<FolderOpenIcon />}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <InformationCard
                    tittle='Monto'
                    amount={data?.totalAmount}
                    icon={<AttachMoneyIcon />}
                    background
                    isAmount
                  />
                </Grid>
              </>
            );
          })}
          <Grid item xs={12}>
            <BarGraphic record={record} stats={stats} labels={labels} />
          </Grid>
          <Grid container spacing={3} item>
            <CardsSegment record={record?.dashboard} />
          </Grid>
          <Grid container spacing={3} item>
            <SegmentGraphic record={record?.dashboard} />
          </Grid>
          <Grid item xs={12}>
            <Table rows={record?.dashboard} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardMasterBroker;
