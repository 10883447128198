import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  maxLength,
  useTranslate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Grid } from '@material-ui/core';

const isRequired = [required()];

const Addresses = ({
  className = '',
  isValidated = false,
  source = 'addresses',
  ...rest
}) => {
  const translate = useTranslate();
  const form = useForm();

  return (
    <ArrayInput
      source={source}
      label=''
      validation={isValidated ? isRequired : false}
      {...rest}
    >
      <SimpleFormIterator>
        <ReferenceInput
          source={rest.record?.id ? 'addressType.id' : 'addressType'}
          reference='address-types'
          variant='outlined'
          validate={isRequired}
          fullWidth
          label={translate('resources.addresses.fields.addressType')}
        >
          <SelectInput />
        </ReferenceInput>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'country.id' : 'country'
                    )}
                    reference='countries'
                    validate={isRequired}
                    onChange={() => {
                      form.change(
                        getSource(rest.record?.id ? 'province.id' : 'province'),
                        ''
                      );
                      form.change(
                        getSource(
                          rest.record?.id ? 'municipality.id' : 'municipality'
                        ),
                        ''
                      );
                      form.change(
                        getSource(
                          rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                        ),
                        ''
                      );
                      form.change(
                        getSource(rest.record?.id ? 'city.id' : 'city'),
                        null
                      );
                    }}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.country')}
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'province.id' : 'province'
                    )}
                    disabled={!scopedFormData?.country}
                    reference='provinces'
                    validate={isRequired}
                    variant='outlined'
                    onChange={() => {
                      form.change(
                        getSource(
                          rest.record?.id ? 'municipality.id' : 'municipality'
                        ),
                        ''
                      );
                      form.change(
                        getSource(
                          rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                        ),
                        ''
                      );
                      form.change(
                        getSource(rest.record?.id ? 'city.id' : 'city'),
                        null
                      );
                    }}
                    fullWidth
                    label={translate('resources.addresses.fields.province')}
                    filter={
                      scopedFormData?.country && {
                        country: rest.record?.id
                          ? scopedFormData.country.id
                          : scopedFormData.country,
                      }
                    }
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'municipality.id' : 'municipality'
                    )}
                    disabled={!scopedFormData?.province}
                    reference='municipalities'
                    validate={isRequired}
                    variant='outlined'
                    onChange={() => {
                      form.change(
                        getSource(
                          rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                        ),
                        ''
                      );
                      form.change(
                        getSource(rest.record?.id ? 'city.id' : 'city'),
                        null
                      );
                    }}
                    fullWidth
                    label={translate('resources.addresses.fields.municipality')}
                    filter={
                      scopedFormData?.province && {
                        province: rest.record?.id
                          ? scopedFormData.province.id
                          : scopedFormData.province,
                      }
                    }
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(rest.record?.id ? 'city.id' : 'city')}
                    reference='cities'
                    disabled={!scopedFormData?.municipality}
                    variant='outlined'
                    fullWidth
                    filter={
                      scopedFormData?.municipality && {
                        municipality: rest.record?.id
                          ? scopedFormData.municipality.id
                          : scopedFormData.municipality,
                      }
                    }
                    label={translate('resources.addresses.fields.city')}
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <ReferenceInput
                    source={getSource(
                      rest.record?.id ? 'neighborhood.id' : 'neighborhood'
                    )}
                    disabled={!scopedFormData?.municipality}
                    reference='neighborhoods'
                    variant='outlined'
                    validate={isRequired}
                    fullWidth
                    label={translate('resources.addresses.fields.neighborhood')}
                    filter={
                      scopedFormData?.municipality && {
                        municipality: rest.record?.id
                          ? scopedFormData.municipality.id
                          : scopedFormData.municipality,
                      }
                    }
                  >
                    <AutocompleteInput />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source={getSource('street')}
                    validate={[required(), maxLength(40)]}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.street')}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextInput
                    source={getSource('outsideNumber')}
                    validate={isRequired}
                    variant='outlined'
                    fullWidth
                    label={translate(
                      'resources.addresses.fields.outsideNumber'
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source={getSource('insideNumber')}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.insideNumber')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    source={getSource('postalCode')}
                    variant='outlined'
                    validate={isRequired}
                    fullWidth
                    label={translate('resources.addresses.fields.postalCode')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    source={getSource('reference')}
                    variant='outlined'
                    fullWidth
                    label={translate('resources.addresses.fields.reference')}
                  />
                </Grid>
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Addresses;
