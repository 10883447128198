import React from 'react';
import type { FC } from 'react';
import {
  Avatar,
  Box,
  Card,
  Typography,
  Divider,
  makeStyles,
  fade,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

import { formatNumber } from '../../../Utils/functions';

interface InformationCardProps {
  tittle?: string;
  amount?: number | string;
  icon?: any;
  background?: boolean;
  isAmount?: boolean;
  percentage?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rootBG: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  contentIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
  },
  contentIconBG: {
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  span: {
    fontFamily: theme.typography.fontFamily,
    marginLeft: '8px',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48,
  },
  avatarBG: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main,
    height: 48,
    width: 48,
  },
  icon: {
    height: 36,
    width: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.08),
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: fade(theme.palette.success.main, 0.08),
  },
  errorBG: {
    color: theme.palette.error.main,
    backgroundColor: '#fff',
  },
  successBG: {
    color: theme.palette.success.main,
    backgroundColor: '#fff',
  },
}));

const InformationCard: FC<InformationCardProps> = ({
  tittle,
  amount,
  icon,
  background,
  isAmount,
  percentage,
  ...rest
}) => {
  const classes = useStyles();
  const color =
    amount > 0
      ? background
        ? 'successBG'
        : 'success'
      : background
      ? 'errorBG'
      : 'error';

  return (
    <Card>
      <div className={background ? classes.rootBG : classes.root} {...rest}>
        <Box flexGrow={1}>
          <Typography component='h3' gutterBottom variant='h6'>
            {tittle}
          </Typography>
          <Box display='flex' alignItems='center' flexWrap='wrap'>
            <Typography variant='h3'>
              {isAmount ? `MXN ${formatNumber(amount)}` : amount}
            </Typography>
          </Box>
        </Box>
        <Avatar className={background ? classes.avatarBG : classes.avatar}>
          {icon}
        </Avatar>
      </div>
      {percentage && (
        <>
          <Divider />
          <div
            className={background ? classes.contentIconBG : classes.contentIcon}
          >
            <Avatar
              className={clsx(classes.icon, {
                [classes[color]]: color,
              })}
            >
              {amount > 0 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Avatar>
            <Typography className={classes.span}>
              12% mas que el mes pasado
            </Typography>
          </div>
        </>
      )}
    </Card>
  );
};

export default InformationCard;
