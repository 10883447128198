import React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core';
import type { Theme } from '../../../../../Theme';
import { formatNumber } from '../../../../../Utils/functions';

interface ChartProps {
  className?: string;
  data: any;
  labels: string[];
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));

const Chart: FC<ChartProps> = ({
  data: dataProp,
  labels,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const backgroundColors = [
    'rgba(255, 99, 132, 0.7)',
    'rgba(54, 162, 235, 0.7)',
    'rgba(255, 206, 86, 0.7)',
    'rgba(75, 192, 192, 0.7)',
    'rgba(153, 102, 255, 0.7)',
    'rgba(255, 159, 64, 0.7)',
  ];
  const datasets = [
    {
      label: 'Monto',
      backgroundColor: backgroundColors,
      data: dataProp.amount,
      borderWidth: 1,
    },
  ];
  const data = {
    datasets,
    labels,
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          locale: 'en-US',
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: 'point',
              intersect: false,
              caretSize: 10,
              padding: 10,
              borderWidth: 1,
              borderColor: theme.palette.divider,
              backgroundColor: theme.palette.background.dark,
              titleColor: theme.palette.text.primary,
              bodyColor: theme.palette.text.secondary,
              footerColor: theme.palette.text.secondary,
              displayColors: false,
              callbacks: {
                label: (value) => `Monto: MXN ${formatNumber(value.raw)}`,
                afterLabel: (value) =>
                  `Cantidad: ${dataProp.quantity[value.dataIndex]}`,
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: theme.palette.text.secondary,
              },
            },
            y: {
              grid: {
                display: true,
                borderDash: [5],
                borderDashOffset: 5,
                color: theme.palette.divider,
                drawBorder: false,
              },
              ticks: {
                color: theme.palette.text.secondary,
                stepSize: 100000000,
                callback: (value) =>
                  `${formatNumber(value)}`.replace('.00', ''),
              },
            },
          },
        }}
      />
    </div>
  );
};

export default Chart;
