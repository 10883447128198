import React from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles,
} from '@material-ui/core';

import Chart from './Chart';

interface BarGraphicProps {
  className?: string;
  stats?: { amount: number[]; quantity: number[] };
  labels?: string[];
}

const useStyles = makeStyles(() => ({
  root: {},
  chart: {
    height: '100%',
  },
}));

const BarGraphic: FC<BarGraphicProps> = ({
  className,
  stats,
  labels,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title='Colocación por estado' />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box height={500} paddingTop={'10px'}>
            <Chart className={classes.chart} data={stats} labels={labels} />
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default BarGraphic;
