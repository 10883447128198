import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  required,
  useDataProvider,
} from 'react-admin';
import { useForm } from 'react-final-form';
import useSelectDataProvider from '../../hooks/useSelectDataProvider';

import { FormShow } from '../../styles';
import { GuaranteeType, GuaranteeTypes, RelatedType } from '../../Utils/enums';
import { formatNumber } from '../../Utils/functions';

const GuaranteeForm = ({ className, formClassName, ...props }: any) => {
  const form = useForm();
  const classes = FormShow();
  const dataProvider = useDataProvider();
  const [guarantor, setGuarantor] = useState<any>({});
  const assetTypes = useSelectDataProvider('asset-types');
  const [values, setValues] = useState(form.getState().values);
  const { person, legalPerson } = values;
  const filter = isNaN(person) ? { legalPerson } : { person };
  const guarantorFilter = guarantor.relatedPerson
    ? { person: guarantor.relatedPerson?.id }
    : { legalPerson: guarantor.relatedLegalPerson?.id };

  const optionText = (record) => {
    const type = assetTypes.filter((assetType) =>
      assetType.id === record.assetType ? assetType : null
    );
    if (record.model) {
      return `${record.brand} ${record.model} - ${
        record.documentNumber
      } - ${formatNumber(record.value)}`;
    } else {
      return `${type[0]?.name} - ${record.documentNumber} - ${formatNumber(
        record.value
      )}`;
    }
  };

  return (
    <Grid container spacing={2} classes={{ root: classes.root }}>
      <Grid item xs={6}>
        <SelectInput
          choices={GuaranteeTypes}
          source='owner'
          resource='guarantees'
          variant='outlined'
          onChange={() => {
            form.change('asset', null);
            form.change('guarantor', null);
            setValues(form.getState().values);
          }}
          fullWidth
        />
      </Grid>
      {values.owner === GuaranteeType.Guarantor && (
        <Grid item xs={6}>
          <ReferenceInput
            source='guarantor'
            reference='relateds'
            resource='guarantees'
            variant='outlined'
            fullWidth
            validate={[required()]}
            onChange={(e) => {
              dataProvider
                .getOne('relateds', { id: e.target.value })
                .then(({ data }) => setGuarantor(data))
                .catch(() => setGuarantor({}));
              form.change('asset', null);
            }}
            filter={
              values.person
                ? {
                    relatedType: RelatedType.Guarantor,
                    person: values.person,
                  }
                : {
                    relatedType: RelatedType.Guarantor,
                    legalPerson: values.legalPerson,
                  }
            }
          >
            <SelectInput
              optionText={(record) =>
                record.relatedPerson
                  ? `${record.relatedPerson.documentNumber} - ${record.relatedPerson.firstName} ${record.relatedPerson.fatherLastName}`
                  : `${record.relatedLegalPerson.documentNumber} - ${record.relatedLegalPerson.businessName}`
              }
            />
          </ReferenceInput>
        </Grid>
      )}
      {(values.owner === GuaranteeType.Own || guarantor.id) && (
        <Grid item xs={6}>
          <ReferenceInput
            source='asset'
            reference='assets'
            variant='outlined'
            fullWidth
            resource='guarantees'
            validate={[required()]}
            filter={
              values.owner === GuaranteeType.Guarantor
                ? guarantorFilter
                : filter
            }
          >
            <SelectInput optionText={optionText} />
          </ReferenceInput>
        </Grid>
      )}
      <Grid item xs={6}>
        <TextInput
          source='observation'
          resource='guarantees'
          variant='outlined'
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default GuaranteeForm;
