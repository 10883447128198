import { Grid } from '@material-ui/core';

import PieGraphic from '../../Components/Graphics/Pie';
import { SegmentState } from '../../../../Utils/enums';

const CardsSegment = ({ record }) => {
  const backgroundColor = [
    '#3d72eb',
    '#b658f5',
    '#4b9e86',
    '#FF5733',
    '#900C3F',
    '#2ECC71',
    '#1F618D',
  ];

  const dataDocument = {
    datasets: [
      {
        backgroundColor,
        data: [],
      },
    ],
    labels: [],
  };

  const dataValidation = {
    datasets: [
      {
        backgroundColor,
        data: [],
      },
    ],
    labels: [],
  };

  const dataDisbursement = {
    datasets: [
      {
        backgroundColor,
        data: [],
      },
    ],
    labels: [],
  };

  for (let actual of record) {
    switch (actual.segment) {
      case SegmentState.segmentState2:
        if (dataDocument.labels.includes(actual.state)) {
          const index = dataDocument.labels.indexOf(actual.state);
          dataDocument.datasets[0].data[index] += parseInt(actual.quantity);
        } else {
          dataDocument.datasets[0].data.push(parseInt(actual.quantity));
          dataDocument.labels.push(actual.state);
        }
        break;
      case SegmentState.segmentState3:
        if (dataValidation.labels.includes(actual.state)) {
          const index = dataValidation.labels.indexOf(actual.state);
          dataValidation.datasets[0].data[index] += parseInt(actual.quantity);
        } else {
          dataValidation.datasets[0].data.push(parseInt(actual.quantity));
          dataValidation.labels.push(actual.state);
        }
        break;
      case SegmentState.segmentState4:
        if (dataDisbursement.labels.includes(actual.state)) {
          const index = dataDisbursement.labels.indexOf(actual.state);
          dataDisbursement.datasets[0].data[index] += parseInt(actual.quantity);
        } else {
          dataDisbursement.datasets[0].data.push(parseInt(actual.quantity));
          dataDisbursement.labels.push(actual.state);
        }
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Grid item lg={4} sm={6} xs={12}>
        <PieGraphic record={dataDocument} noLabel />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <PieGraphic record={dataValidation} noLabel />
      </Grid>
      <Grid item lg={4} sm={6} xs={12}>
        <PieGraphic record={dataDisbursement} noLabel />
      </Grid>
    </>
  );
};

export default CardsSegment;
