import * as React from 'react';
import {
  ArrayField,
  Datagrid,
  TabbedShowLayout,
  Tab,
  TextField,
  FunctionField,
  NumberField,
} from 'react-admin';

import { FormShow } from '../../styles';
import TablePlaceholder from '../../Components/TablePlaceholder';

export const GuaranteePanelShow = () => {
  const classes = FormShow();

  return (
    <TabbedShowLayout className={classes.borderBottom}>
      <Tab label='Solicitudes Asociadas' contentClassName={classes.flexParent}>
        <ArrayField
          fieldKey='loanApplication'
          addLabel={false}
          label=''
          source='loanApplicationInfo'
          className={classes.width100}
        >
          <Datagrid empty={<TablePlaceholder title='Solicitudes Asociadas' />}>
            <TextField
              source='loanApplication'
              emptyText='-'
              label='Id de Solicitud'
            />
            <FunctionField
              label='Nombre del cliente'
              source='person'
              render={(record) =>
                record?.clientPerson ? (
                  <TextField source='clientPerson' emptyText='-' />
                ) : (
                  <TextField source='clientLegalPerson' emptyText='-' />
                )
              }
            />
            <TextField
              source='applicationType'
              emptyText='-'
              label='Tipo de producto'
            />
            <NumberField
              source='amount'
              label='Monto'
              emptyText='-'
              options={{ style: 'currency', currency: 'MXN' }}
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  );
};

export default GuaranteePanelShow;
