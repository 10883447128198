import { FormWithRedirect, required, SaveButton, TextInput } from 'react-admin';
import { Box, Toolbar, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      boxShadow: 'none',
      color: theme.palette.text.secondary,
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
  })
);

interface OccupationInterface {
  props?: object;
}

const isRequired = [required()];

export const OccupationForm: React.FC<OccupationInterface> = (props) => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      source='name'
                      resource='occupations'
                      validate={isRequired}
                      variant='outlined'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <SaveButton
                  saving={formProps.saving}
                  disabled={formProps.invalid}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

export default OccupationForm;
