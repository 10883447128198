import { Edit, ListButton, TopToolbar } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import NotaryForm from './NotaryForm';

const NotaryActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const NotaryEdit = (props: any) => {
  return (
    <Edit actions={<NotaryActions {...props} />} {...props}>
      <NotaryForm />
    </Edit>
  );
};

export default NotaryEdit;
