import React, { useEffect, useState } from 'react';
import {
  Button,
  FormWithRedirect,
  NumberInput,
  useTranslate,
  SelectInput,
  required,
  SimpleForm,
  useNotify,
} from 'react-admin';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { FormShow } from '../../styles';
import { formatNumber } from '../../Utils/functions';
import { changeMade, errors, thereWasProblem } from '../../Utils/messages';
import { AuthRole } from '../../Utils/enums';
import FormatNumberInput from '../../Components/FormatNumberInput';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    position: 'relative',
    '& div.ql-editor': {
      minHeight: '80px',
    },
    button: {
      margin: '0rem 0rem 1rem 0rem;',
    },
  },
  button: {
    marginRight: '1rem',
  },
  value: {
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    margin: '6px 0px',
  },
  label: {
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
  },
}));

const RenderValues = ({ label, value, isAmount = true }) => {
  const CLS = FormShow();
  const classes = useStyles();

  return (
    <div className={CLS.width33}>
      <label className={classes.label}>{label}</label>
      <p className={classes.value}>
        {value !== undefined && value !== null
          ? isAmount
            ? formatNumber(value)
            : value
          : '-'}
      </p>
    </div>
  );
};

const RatingForm = ({
  data,
  loanApplicationId,
  calculateRating,
  saveRating,
  setRatingData,
  getRatingData,
  loanApplication,
  setData,
}) => {
  const CLS = FormShow();
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const user = JSON.parse(localStorage.getItem('user'));
  const [showRating, setShowRating] = useState(true);
  const [canSave, setCanSave] = useState(false);
  const [saveValues, setSaveValues] = useState<any>();
  const [rating, setRating] = useState('');

  const choices = [
    {
      id: 700,
      name: '700',
    },
    {
      id: 650,
      name: '650',
    },
    {
      id: 600,
      name: '600',
    },
    {
      id: 550,
      name: '550',
    },
    {
      id: 500,
      name: '500',
    },
    {
      id: 450,
      name: '450',
    },
    {
      id: 400,
      name: '400',
    },
  ];

  const choicesRating = [
    {
      id: 'AA',
      name: 'AA',
    },
    {
      id: 'A',
      name: 'A',
    },
    {
      id: 'B',
      name: 'B',
    },
    {
      id: 'C',
      name: 'C',
    },
    {
      id: 'D',
      name: 'D',
    },
    {
      id: 'E',
      name: 'E',
    },
    {
      id: 'F',
      name: 'F',
    },
  ];

  const totals = {
    guarantee: 0,
    quota: data?.quota,
  };

  useEffect(() => {
    if (loanApplication?.applicationType?.name !== 'P2P') {
      getRatingData(loanApplicationId).then((rest) => setData(rest));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data && !data?.statusCode) {
    for (let i = 0; i < data?.guarantees.length; i++) {
      totals.guarantee += data.guarantees[i].asset.value;
    }
  }

  const onChange = (e) => {
    setRating(e.target.value);
    setShowRating(false);
    getRatingData(loanApplicationId, e.target.value).then((rest) =>
      setData(rest)
    );
  };

  if (loanApplication?.applicationType?.name === 'P2P' && showRating) {
    return (
      <SimpleForm toolbar={false}>
        <SelectInput
          choices={choicesRating}
          source='rating'
          fullWidth
          variant='outlined'
          validate={required()}
          label={translate('resources.rating.fields.rating')}
          onChange={onChange}
        />
      </SimpleForm>
    );
  }

  const handleSubmit = async (values, totals) => {
    const dataForRating = {
      cat: values?.cat,
      rating: values?.rating,
      loanApplication: Number(loanApplicationId),
      dueRequest: Number(totals.quota),
      score: values.ratingScore ? values.ratingScore : 0,
      dueOther: values.otherQuota ? values.otherQuota : 0,
      totalGuarantee: totals.guarantee,
    };

    setCanSave(true);
    setSaveValues(dataForRating);

    calculateRating(dataForRating).then((rest) => {
      if (rest.statusCode === 400) {
        const code = rest.data.errorCode;
        return notify(
          `${thereWasProblem}: ${
            errors[code] ||
            'Hubo un error con su solicitud, inténtelo mas tarde...'
          }`,
          'warning'
        );
      }

      setRatingData(rest);
    });
  };

  const handleSave = async (values) => {
    saveRating(values).then((rest) => {
      if (rest.statusCode === 400 || rest.statusCode > 403) {
        const code = rest.data?.errorCode;
        return notify(
          `${thereWasProblem}: ${
            errors[code] ||
            'Hubo un error con su solicitud, inténtelo mas tarde...'
          }`,
          'warning'
        );
      }

      return notify(changeMade, 'info');
    });
  };

  return (
    <Grid container spacing={2} style={{ padding: '16px' }}>
      <Grid item xs={12}>
        <Typography variant='h4' gutterBottom>
          Información general
        </Typography>
      </Grid>
      <Grid item xs={12} className={CLS.flexParent}>
        <RenderValues
          label={translate('resources.rating.fields.income')}
          value={data?.incomes}
        />
        <RenderValues
          label={translate('resources.rating.fields.quota')}
          value={data?.quota}
        />
        <RenderValues
          label={translate('resources.rating.fields.guaranteeValue')}
          value={totals.guarantee}
        />
        {[AuthRole.Admin, AuthRole.Risk].includes(user.role.id) && (
          <RenderValues
            label={translate('resources.rating.fields.contractValue')}
            value={data?.contractValue}
          />
        )}
        <RenderValues
          label={translate('resources.rating.fields.amount')}
          value={loanApplication?.amount}
        />
        <RenderValues
          label={translate('resources.rating.fields.term')}
          value={loanApplication?.months}
          isAmount={false}
        />
        <RenderValues
          label={translate('resources.rating.fields.interest')}
          value={loanApplication?.interestRate}
          isAmount={false}
        />
        {(data?.person?.score || data?.legalPerson?.score) && (
          <RenderValues
            label={translate('resources.rating.fields.ratingScore')}
            value={data?.person ? data?.person.score : data?.legalPerson.score}
            isAmount={false}
          />
        )}
        {(data?.person?.score || data?.legalPerson?.score) && (
          <RenderValues
            label={translate('resources.rating.fields.otherQuota')}
            value={
              data?.person
                ? data?.person.ratingQuota
                : data?.legalPerson.ratingQuota
            }
          />
        )}
        {(data?.person?.cat || data?.legalPerson?.cat) && (
          <RenderValues
            label={translate('resources.rating.fields.cat')}
            value={data?.person ? data?.person.cat : data?.legalPerson.cat}
            isAmount={false}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <FormWithRedirect
          className={classes.root}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, saving }) => (
            <Grid container spacing={2} xs={12}>
              {loanApplication?.applicationType?.name === 'P2P' && (
                <SelectInput
                  choices={choicesRating}
                  defaultValue={rating}
                  source='rating'
                  fullWidth
                  variant='outlined'
                  validate={required()}
                  label={translate('resources.rating.fields.rating')}
                  onChange={onChange}
                />
              )}
              <FormatNumberInput source='cat' resource='rating' />
              {user.role.id === AuthRole.Risk ||
              user.role.id === AuthRole.Admin ? (
                <NumberInput
                  source='ratingScore'
                  fullWidth
                  variant='outlined'
                  validate={required()}
                  label={translate('resources.rating.fields.ratingScore')}
                />
              ) : (
                !data?.person?.score &&
                !data?.legalPerson?.score && (
                  <SelectInput
                    choices={choices}
                    source='ratingScore'
                    fullWidth
                    variant='outlined'
                    validate={required()}
                    label={translate('resources.rating.fields.ratingScore')}
                  />
                )
              )}
              {((!data?.person?.score && !data?.legalPerson?.score) ||
                user.role.id === AuthRole.Risk ||
                user.role.id === AuthRole.Admin) && (
                <FormatNumberInput source='otherQuota' resource='rating' />
              )}
              {((!data?.person?.score && !data?.legalPerson?.score) ||
                user.role.id === AuthRole.Risk ||
                user.role.id === AuthRole.Admin) && (
                <Button
                  onClick={() => handleSubmitWithRedirect(totals)}
                  className={classes.button}
                  variant='contained'
                  saving={saving}
                  label='Calcular'
                />
              )}
              {(user.role.id === AuthRole.Risk ||
                user.role.id === AuthRole.Admin) && (
                <Button
                  onClick={() => handleSave(saveValues)}
                  className={classes.button}
                  variant='contained'
                  disabled={!canSave}
                  label='Guardar'
                />
              )}
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default RatingForm;
