import { Fragment, useCallback } from 'react';
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, makeStyles } from '@material-ui/core';

import DocumentTypeCreate from './DocumentTypeCreate';
import DocumentTypeEdit from './DocumentTypeEdit';

import ListActions from '../../Components/ListActions';
import TablePlaceholder from '../../Components/TablePlaceholder';
import CustomList from '../../Components/CustomList';

const useStyles = makeStyles(() => ({
  drawerContent: { width: '50vw', padding: '0 1rem' },
}));

const Filters = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput label='ra.action.search' source='name' alwaysOn />
    <ReferenceInput
      reference='document-segments'
      source='documentSegment'
      resettable
    >
      <SelectInput />
    </ReferenceInput>
  </Filter>
);

const DocumentTypeList = ({ permissions, hasCreate, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/document-types');
  }, [history]);

  return (
    <Fragment>
      <CustomList
        {...props}
        hasCreate={true}
        filters={<Filters />}
        actions={
          <ListActions
            hasCreate={true}
            isMaintenance
            entity='document-type'
            permissions={permissions}
            {...props}
          />
        }
      >
        <Datagrid
          rowClick='edit'
          empty={<TablePlaceholder title='DocumentType' />}
        >
          <TextField source='id' />
          <TextField source='name' />
          <FunctionField
            source='segment'
            render={(record) =>
              record.segment === 'files' ? 'Documento' : 'Número de documento'
            }
          />
          <TextField source='documentSegment.name' />
        </Datagrid>
      </CustomList>
      <Route path='/document-types/create'>
        {({ match }) => (
          <Drawer open={!!match} anchor='right' onClose={handleClose}>
            <DocumentTypeCreate
              className={classes.drawerContent}
              onCancel={handleClose}
              {...props}
            />
          </Drawer>
        )}
      </Route>
      <Route path='/document-types/:id'>
        {({ match }) => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Drawer open={isMatch} anchor='right' onClose={handleClose}>
              {isMatch ? (
                <DocumentTypeEdit
                  className={classes.drawerContent}
                  id={match.params.id}
                  onCancel={handleClose}
                  {...props}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

export default DocumentTypeList;
