import React from 'react';
import { Container, makeStyles, Grid, Typography } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';
import type { Theme } from '../../../theme';
import Page from '../../../Components/Page';
import InformationCard from '../Components/InformationCard';
import PieGraphic from '../Components/Graphics/Pie';
import Header from '../Components/Header';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  card: {
    paddingInline: 5,
  },
}));

const DashboardRisk = ({ record }: any) => {
  const classes = useStyles();
  let totalLoan = 0;
  const labels = [];

  const attended = [];
  const pending = [];
  const attendedAmount = [];
  const pendingAmount = [];

  const backgroundColor = [
    '#3d72eb',
    '#b658f5',
    '#4b9e86',
    '#FF5733',
    '#900C3F',
    '#2ECC71',
    '#1F618D',
  ];

  const dataPendingApplications = {
    datasets: [
      {
        backgroundColor,
        data: [],
      },
    ],
    labels: [],
  };

  const dataApplicationsBySubState = {
    datasets: [
      {
        backgroundColor,
        data: [],
      },
    ],
    labels: [],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: '',
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Solicitudes Atendidas',
        backgroundColor: 'rgb(0, 0, 255)',
        borderColor: 'rgb(0, 0, 255)',
        data: attendedAmount,
      },
      {
        label: 'Solicitudes Recibidas',
        backgroundColor: 'rgba(60, 179, 113)',
        borderColor: 'rgba(60, 179, 113)',
        data: pendingAmount,
      },
    ],
  };
  record.dashboardInfo.sort(function (a, b) {
    return a.pastDays - b.pastDays;
  });

  for (let res of record.dashboardInfo) {
    labels.push(res.pastDays);

    attendedAmount.push(parseInt(res.attendedAmount));
    attended.push(parseInt(res.attendedQuantity));

    pending.push(parseInt(res.pendingQuantity));
    pendingAmount.push(parseInt(res.pendingAmount));
  }

  for (let res of record.dashboardState) {
    totalLoan += parseInt(res.quantity);

    if (dataPendingApplications.labels.includes(res.analyst)) {
      const index = dataPendingApplications.labels.indexOf(res.analyst);
      dataPendingApplications.datasets[0].data[index] += parseInt(res.quantity);
    } else {
      dataPendingApplications.datasets[0].data.push(parseInt(res.quantity));
      dataPendingApplications.labels.push(res.analyst);
    }
  }

  for (let res of record.dashboardSubstate) {
    if (dataApplicationsBySubState.labels.includes(res.substate)) {
      const index = dataApplicationsBySubState.labels.indexOf(res.substate);
      dataApplicationsBySubState.datasets[0].data[index] += parseInt(
        res.quantity
      );
    } else {
      dataApplicationsBySubState.datasets[0].data.push(parseInt(res.quantity));
      dataApplicationsBySubState.labels.push(res.substate);
    }
  }

  return (
    <Page className={classes.root} title='IDEXI'>
      <Container maxWidth='lg'>
        <Header altText='solicitudes' />
        <Grid container spacing={1}>
          <Grid
            item
            lg={record?.franchiseQty || record?.franchiseQty === 0 ? 4 : 6}
            sm={6}
            xs={12}
          >
            <InformationCard
              tittle='Solicitudes'
              amount={totalLoan}
              icon={<FolderOpenIcon />}
            />
          </Grid>
          <Grid container item spacing={3}>
            <Grid item xs={12}>
              <Typography variant='subtitle1' color='textPrimary'>
                Solicitudes atendidas y Solicitudes recibidas
              </Typography>
              <Bar data={data} options={options} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' color='textPrimary'>
                Solicitudes pendientes por analista.
              </Typography>
              <PieGraphic record={dataPendingApplications} noLabel />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' color='textPrimary'>
                Solicitudes por Sub-Estado
              </Typography>
              <PieGraphic record={dataApplicationsBySubState} noLabel />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardRisk;
