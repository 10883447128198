import * as React from 'react';
import { List, Pagination } from 'react-admin';

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 15, 25, 50, 100]} {...props} />
);

const CustomList = ({ children, ...props }) => (
  <List
    hasCreate
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<CustomPagination />}
    perPage={15}
    {...props}
  >
    {children}
  </List>
);


export default CustomList;
