import React, { useState } from 'react';
import {
  SelectInput,
  ReferenceInput,
  required,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  FormWithRedirect,
  useNotify,
  SaveButton,
} from 'react-admin';

import { Box, Grid, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import Cookies from '../../Utils/cookies';
import Conditions from '../../Components/Conditions';
import Documents from '../../Components/Documents';
import Positions from '../../Components/Positions';
import FormTitle from '../../Components/FormTitle';
import { Company, StateType } from '../../Utils/enums';
import type { Theme } from '../../Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    width100: {
      width: '100%',
      fontSize: '15px',
      marginBottom: '10px',
      '& > p': {
        display: 'none',
      },
    },
  })
);

const StateRuleForm = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const [values, setValues] = useState<any>();
  const [futureStates, setFutureStates] = useState([]);

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const company = user.company?.id || Company.IbanOnlineMx;

  const getFutureStatesByActualStateId = (actualStateId) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/permitted-states?actual_eq=${actualStateId}`,
      {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${Cookies.getCookie('token')}`,
        },
      }
    )
      .then(async (res) => {
        const permittedStates = await res.json();
        if (permittedStates.statusCode === 403) {
          window.location.href = `/#/403`;
          return;
        }

        setFutureStates([]);
        permittedStates.map((permittedState) =>
          setFutureStates((state) => [...state, permittedState.future])
        );
      })
      .catch(() => {
        notify('Hubo un error.', 'warning');
      });
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        if (!values) setValues(formProps.record);

        return (
          <>
            <Grid container spacing={2} classes={{ root: classes.root }}>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  General
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <TextInput
                      source='name'
                      resource='state-rules'
                      validate={required()}
                      fullWidth
                      disabled={props.record?.id}
                      className={classes.width100}
                      variant='outlined'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <ReferenceInput
                      source='actualState'
                      resource='state-rules'
                      reference='states'
                      validate={required()}
                      onChange={(e) => {
                        setValues(formProps.form.getState().values);
                        getFutureStatesByActualStateId(e.target.value);
                        formProps.form.change('futureState', '');
                      }}
                      fullWidth
                      disabled={props.record?.id}
                      className={classes.width100}
                      variant='outlined'
                      filter={{
                        stateTypeId:
                          company === Company.IbanOnlineMx
                            ? StateType.LoanApplicationIO
                            : StateType.LoanApplicationKap,
                      }}
                    >
                      <SelectInput optionText='name' />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={4}>
                    {props.record?.id ? (
                      <ReferenceInput
                        source='futureState'
                        label='resources.state-rules.fields.futureState'
                        reference='states'
                        disabled
                        validate={required()}
                        className={classes.width100}
                        fullWidth
                        variant='outlined'
                        filter={{
                          stateTypeId:
                            company === Company.IbanOnlineMx
                              ? StateType.LoanApplicationIO
                              : StateType.LoanApplicationKap,
                        }}
                      >
                        <SelectInput optionText='name' />
                      </ReferenceInput>
                    ) : (
                      <SelectInput
                        source='futureState'
                        label='resources.state-rules.fields.futureState'
                        validate={required()}
                        disabled={!values?.actualState}
                        fullWidth
                        choices={futureStates}
                        className={classes.width100}
                        variant='outlined'
                        optionText='name'
                      />
                    )}
                  </Grid>
                  <Grid item xs={7}>
                    <Documents record={props.record} />
                  </Grid>
                  <Grid item xs={5}>
                    <ArrayInput
                      source='applicationTypes'
                      label='Tipos de aplicación'
                      validate={required()}
                    >
                      <SimpleFormIterator>
                        <ReferenceInput
                          source={props.record?.id ? 'id' : ''}
                          reference='application-types'
                          validate={required()}
                          variant='outlined'
                          label=''
                          fullWidth
                          filter={{
                            company,
                          }}
                        >
                          <SelectInput />
                        </ReferenceInput>
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Grid>
                  <Grid item xs={7}>
                    <FormTitle title='Condiciones' />
                    <Conditions record={props.record} />
                  </Grid>
                  <Grid item xs={5}>
                    <FormTitle title='Posiciones' />
                    <Positions record={props.record} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Toolbar>
              <Box display='flex' justifyContent='space-between' width='100%'>
                <SaveButton
                  saving={formProps.saving}
                  disabled={formProps.invalid}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  redirect='list'
                />
              </Box>
            </Toolbar>
          </>
        );
      }}
    />
  );
};

export default StateRuleForm;
