import React, { useState, useEffect } from 'react';
import type { FC } from 'react';

import { AuthRole } from '../../Utils/enums';
import Cookies from '../../Utils/cookies';
import DashboardMasterBroker from './DashboardMasterBroker';
import DashboardAgent from './DashboardAgent';
import DashboardAdmin from './DashboardAdmin';
import ShowHeader from './Components/ShowHeader';
import PromotionModal from '../Promotions/PromotionModal';
import DashboardRisk from './DashboardRisk';
import DashboardFormalization from './DashboardFomalization';

const Dashboard: FC = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [data, setData] = useState<any>();
  const getDataDashboard = async (zone?: string) => {
    const token = Cookies.getCookie('token');
    const data = await fetch(
      zone
        ? `${process.env.REACT_APP_API_URL}/dashboard?department=${zone}`
        : `${process.env.REACT_APP_API_URL}/dashboard`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((rest) => rest.json());
    if (!data.error) setData(data);
  };

  useEffect(() => {
    getDataDashboard();
  }, []);

  if (!data)
    return (
      <>
        <PromotionModal />
        <ShowHeader />
      </>
    );

  switch (user.role.id) {
    case AuthRole.Business:
      return (
        <>
          <PromotionModal />
          <DashboardAgent record={data} />
        </>
      );
    case AuthRole.MasterBroker:
      return (
        <>
          <PromotionModal />
          <DashboardMasterBroker record={data} />
        </>
      );
    case AuthRole.Lead:
    case AuthRole.Admin:
    case AuthRole.Audit:
      return (
        <>
          <PromotionModal />
          <DashboardAdmin record={data} getDashboard={getDataDashboard} />
        </>
      );
    case AuthRole.Risk:
      return <DashboardRisk record={data} />;
    case AuthRole.Formalization:
      return <DashboardFormalization record={data} />;
    default:
      return (
        <>
          <PromotionModal />
          <ShowHeader />
        </>
      );
  }
};

export default Dashboard;
