import React from 'react';
import { Divider, Grid, List, Paper, Typography } from '@material-ui/core';

import {
  applicationReasons,
  applicationSource,
  applicationTypes,
  clientSource,
  evaluationUnits,
  notaries,
} from '../../../Views';
import ListItemLink from '../ListItemLink';

const LoanApplication = ({ classes, iconColor }) => {
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant='h4' component='h2'>
          Solicitudes
        </Typography>
        <Divider />
        <List className={classes.list} aria-labelledby='conjuration subtitle'>
          <ListItemLink
            icon={<applicationReasons.icon />}
            primary='Razones de las Solicitudes'
            to='/application-reasons'
            color={iconColor}
          />
          <ListItemLink
            icon={<applicationSource.icon />}
            primary='Fuentes de las Solicitudes'
            to='/application-sources'
            color={iconColor}
          />
          <ListItemLink
            icon={<applicationTypes.icon />}
            primary='Tipos de Solicitudes'
            to='/application-types'
            color={iconColor}
          />
          <ListItemLink
            icon={<clientSource.icon />}
            primary='Fuentes de Clientes'
            to='/client-sources'
            color={iconColor}
          />
          <ListItemLink
            icon={<evaluationUnits.icon />}
            primary='Unidades de evaluación'
            to='/evaluation-units'
            color={iconColor}
          />
          <ListItemLink
            icon={<notaries.icon />}
            primary='Notarías'
            to='/notaries'
            color={iconColor}
          />
        </List>
      </Paper>
    </Grid>
  );
};

export default LoanApplication;
