import React from 'react';
import { parse } from 'query-string';
import {
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  useRedirect,
  BooleanField,
} from 'react-admin';

import CustomList from '../../Components/CustomList';
import FullNameField from '../../Components/FullNameField';
import { BlacklistSegment } from '../../Utils/enums';

const Blacklist = ({ permissions, ...props }) => {
  const redirect = useRedirect();
  const { segment } = parse(props.location.search);

  return (
    <CustomList filter={segment ? { segment } : {}} actions={<></>} {...props}>
      <Datagrid>
        <TextField source='data.id' emptyText='-' />
        <FunctionField
          source='data'
          render={(record) => {
            if (!record?.data) return '-';

            switch (record.segment) {
              case BlacklistSegment.Person:
                return (
                  <div
                    onClick={() => redirect(`/people/${record.data.id}/show`)}
                  >
                    <FullNameField
                      record={record}
                      source='data'
                      entity='data'
                    />
                  </div>
                );
              case BlacklistSegment.LegalPerson:
                return (
                  <TextField
                    source='data.businessName'
                    onClick={() =>
                      redirect(`/legal-people/${record.data.id}/show`)
                    }
                  />
                );
              case BlacklistSegment.Franchise:
                return (
                  <TextField
                    source='data.name'
                    onClick={() =>
                      redirect(`/franchises/${record.data.id}/show`)
                    }
                  />
                );
              default:
                return '-';
            }
          }}
        />
        {segment === BlacklistSegment.Franchise ? (
          <TextField source='data.code' />
        ) : (
          <TextField source='data.documentNumber' />
        )}
        <FunctionField
          source='agent'
          render={(record) => {
            if (!record?.data) return '-';

            return record.data.agent
              ? `${record.data.agent?.code ?? ''} | ${
                  record.data.agent?.person?.firstName ?? ''
                } ${record.data.agent?.person?.fatherLastName ?? ''}`
              : '-';
          }}
        />
        <TextField source='dictum.code' emptyText='-' />
        <BooleanField source='data.blacklist' emptyText='-' />
        <DateField source='created_at' showTime={false} locales='es-MX' />
        <DateField source='exitDate' showTime={false} locales='es-MX' />
      </Datagrid>
    </CustomList>
  );
};

export default Blacklist;
