import React, { useState } from 'react';
import RatingForm from './RatingForm';
import { Grid } from '@material-ui/core';
import RatingValue from './RatingValue';
import Cookies from '../../Utils/cookies';

const getRatingData = async (id, rating = null) => {
  const token = Cookies.getCookie('token');

  const data = await fetch(
    `${process.env.REACT_APP_API_URL}/rating?loanApplication=${id}${
      rating ? `&rating=${rating}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(async (res) => {
    const parsedData = await res.json();
    if (parsedData.statusCode === 403) {
      window.location.href = `/#/403`;
      return;
    }

    return parsedData;
  });

  return data;
};

const calculateRating = async (dataSend) => {
  const token = Cookies.getCookie('token');

  const data = await fetch(`${process.env.REACT_APP_API_URL}/rating`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataSend),
  }).then(async (res) => {
    const parsedData = await res.json();
    if (parsedData.statusCode === 403) {
      window.location.href = `/#/403`;
      return;
    }

    return parsedData;
  });

  return data;
};

const saveRating = async (dataSend) => {
  const token = Cookies.getCookie('token');

  const data = await fetch(`${process.env.REACT_APP_API_URL}/rating/save`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataSend),
  }).then(async (res) => {
    const parsedData = await res.json();
    if (parsedData.statusCode === 403) {
      window.location.href = `/#/403`;
      return;
    }

    return parsedData;
  });

  return data;
};

const Rating = ({ record, ...rest }) => {
  const [data, setData] = useState();
  const [ratingData, setRatingData] = useState();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <RatingForm
          data={data}
          loanApplicationId={record.id}
          loanApplication={record}
          calculateRating={calculateRating}
          saveRating={saveRating}
          setRatingData={setRatingData}
          getRatingData={getRatingData}
          setData={setData}
        />
      </Grid>
      <Grid item xs={6}>
        <RatingValue ratingData={ratingData} record={record} />
      </Grid>
    </Grid>
  );
};

export default Rating;
