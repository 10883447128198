import { Edit, ListButton, TopToolbar } from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import DepartmentForm from './DepartmentForm';

const DepartmentActions = ({ basePath }: any) => (
  <TopToolbar>
    <ListButton
      basePath={basePath}
      label='ra.action.back'
      icon={<ChevronLeft />}
    />
  </TopToolbar>
);

const DepartmentEdit = (props: any) => {
  return (
    <Edit actions={<DepartmentActions {...props} />} {...props}>
      <DepartmentForm />
    </Edit>
  );
};

export default DepartmentEdit;
